import styled from "styled-components";
import {rem} from "../../app/styles";
import {TrainingCardWrapper} from "../TrainingCard/TrainingCard.styles";
import {TrainingPageInfo} from "../TrainingPageContent/TrainingPageContent.styles";

export const MyTrainingCardWrapper = styled(TrainingCardWrapper)`
  width: 100%;
  position: relative
`

export const MyTrainingsPageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
`

export const MyTrainingCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 15%;
  width: 100%;
`

export const MyTrainingInfo = styled(TrainingPageInfo)`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  width: 100%;
`

export const MyTrainingBtn = styled.button`
  position: absolute;
  bottom: ${rem(15)};
  right: ${rem(15)};
  display: flex;
  align-self: flex-end;
  background: #FF3E1D;
  &:hover {
    color: #FF3E1D
  }
`

export const MyTrainingsTitle = styled.h3`
    position: absolute;
    color: var(--color-text);
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
`

export const MyTrainingsTitleNo = styled.h3`
    position: absolute;
    color: var(--color-text);
    text-align: center;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
`

export const MyTrainingsHeader = styled.div`
  border-bottom: 1px solid var(--color-third);
  padding: ${rem(40)};
`

export const MyTrainingsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  padding: ${rem(20)};
`