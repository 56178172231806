import styled from "styled-components";
import { rem } from "../../app/styles";
import { Input } from "../../ui-kit/Input";

export const ErrorPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 80%;
    height: 100vh;
    text-align: center;
`;

export const ErrorPageTypography = styled.p`
    color: var(--color-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
`;

export const ErrorPageMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${rem(20)};
`;

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64px;
    height: 64px;
    color: white;
`;

export const ButtonWrapper = styled.div`
  margin-top: ${rem(20)};
`;

