import styled, {keyframes} from "styled-components";
import {DatePicker} from "../DatePicker";
import {rem} from "../../app/styles";
import Skeleton from "react-loading-skeleton";
import {Transition} from "@headlessui/react";

const anim = keyframes`
  0% {
    background: var(--color-primary);
  }

  100% {
    background: transparent;
    //z-index: -1;
  }
`;

export const SchedulePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(15)};
  align-items: center;
`;

export const SchedulePageDatepicker = styled(DatePicker)<{
  isFloating?: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
`;

export const ScheduleWrapper = styled.div`
  position: relative;
  padding-top: ${rem(200)};
  padding-bottom: ${rem(90)};
  width: 100%;

  @media (max-width: ${rem(345)}) {
    padding-top: ${rem(270)};
  }
`;

export const ScheduleAnimationBg = styled.div<{ showElement?: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;

  animation: ${anim} 0.5 forwards;

  transition: background 0.5s;
`

export const ScheduleFadeAnimation = styled(Transition)`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
`

export const TrainingCardBasis = styled(Skeleton).attrs({
  style: {
    padding: '15px',
    width: '100%',
    height: '100%'
  }
})`
  margin-top: 15px;
`