import { useEffect, useState } from "react";
import { Input } from "../../ui-kit/Input";
import {
    FilterInputWrapper,
    FiltersPageLayout,
    FiltersTitle,
    FilterVariant, FilterVariantWrapper,
    TrainingFilterWrapper,
    VariantsWrapper,
} from "./FiltersPage.styles";
import axios from "axios";
import {ReactComponent as CheckboxIcon} from './checkbox.svg'
import {ReactComponent as UncheckboxIcon} from "./Uncheckbox.svg";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setTrainingFilter} from "../Schedule/schedule.slice";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {useNavigate} from "react-router-dom";
import {ReactComponent as SearchSvg} from './Search.svg'
import {useSendToAuth} from "../../hooks/useSendToAuth";
import { FiltersLayout } from "../../app/layouts";
import {ERoutes} from "../../app/AppRouter.types";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {isEditable} from "@testing-library/user-event/dist/utils";
import {doc} from "prettier";
// @ts-ignore
import isEmpty = doc.utils.isEmpty;

export const TrainingFilter = () => {
    const [variants, setVariants] = useState<string[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sendToAuth = useSendToAuth()

  const handleGetBack = () => {
    navigate(-1);
  };

    const handleGetVariants = () => {
        const res = axios.get('https://api.profitnes.club/api/get-services').then((res) => {
            setVariants(res?.data)
        }).catch((err) => {
            if(err?.response?.status === 401) {
                sendToAuth()
            }

          const stringErrorStatus = err?.response?.status.toString()

          if(stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
            navigate(ERoutes.MAIN)
          }
        })
    }

  useEffect(() => {
    handleGetVariants();
  }, []);

  const handleSelectOption = (option: string) => {
    if (selectedOptions.includes(option)) {
      const filteredOptions = selectedOptions.filter((opt) => opt !== option);
      setSelectedOptions(filteredOptions);
    } else setSelectedOptions([...selectedOptions, option]);
  };

  const handleSubmitFilters = () => {
    dispatch(setTrainingFilter(selectedOptions));
    navigate("/");
  };

  return (
      <>
          <FadeAnimation show={Boolean(variants.length == 0)}>
              <AbsolutePreloader/>
          </FadeAnimation>
          <FadeAnimation show={Boolean(variants.length > 0)}>
    <FiltersLayout onClick={handleSubmitFilters}>
      <FiltersPageLayout>
          <BackButton onClick={handleGetBack}/>
        <TrainingFilterWrapper>
          <FiltersTitle>Вид занятий</FiltersTitle>
            <FilterInputWrapper>
                <SearchSvg />
                <Input
                    variant={'primary'}
                    placeholder={'Искать'}
                    onChange={e => setSearchValue(e.target.value.toLowerCase())}
                />
            </FilterInputWrapper>
          <VariantsWrapper>
            {variants
              .filter((variant) => variant.toLowerCase().includes(searchValue))
              .map((variant, index) => (
                  <FilterVariantWrapper
                      onClick={(e) => handleSelectOption(variant)}
                      key={index}>
                    <FilterVariant>
                      {variant}
                    </FilterVariant>
                      {selectedOptions.includes(variant) ? <CheckboxIcon /> : <UncheckboxIcon />}
                  </FilterVariantWrapper>
              ))}
          </VariantsWrapper>
        </TrainingFilterWrapper>
      </FiltersPageLayout>
    </FiltersLayout>
          </FadeAnimation>
          </>
  );
};
