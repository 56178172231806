import {TrainingContent} from "../TrainingPageContent/TrainingContent";
import {useEffect, useState} from "react";
import {ITrainingInfo} from "../TrainingCard/model/TrainingCard.types";
import axios from "axios";
import {useNavigate, useParams} from "react-router-dom";
import {useSendToAuth} from "../../hooks/useSendToAuth";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {useAppSelector} from "../../hooks/useAppSelector";
import {ERoutes} from "../../app/AppRouter.types";

export interface IGetTrainingResponse {
  Request_id: string
  Parameters: ITrainingInfo
}

export const TrainingPage = () => {
  const params = useParams()
  const sendToAuth = useSendToAuth()
  const queryUrl = 'https://api.profitnes.club/api/get-schedule-item'
  const [training, setTraining] = useState<ITrainingInfo>()
  const [isTrainingLoading, setIsTrainingLoading] = useState(true)
  const userAppointedTrainings = useAppSelector(state => state.user.appointed)
  const navigate = useNavigate()

  async function fetchTraining() {
    setIsTrainingLoading(true)
    try {
      const response = await axios.post(queryUrl, {
        AppointmentID: params.id
      })
      // @ts-ignore
      setTraining(response?.data?.Parameters)
    } catch (err) {
      if (err?.response?.status === 401) {
        sendToAuth()
      }

      const stringErrorStatus = err?.response?.status.toString()

      if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
        navigate(ERoutes.MAIN)
      }
    } finally {
      setIsTrainingLoading(false)
    }
  }

  useEffect(() => {
    fetchTraining()
  }, [params, userAppointedTrainings])

  return (
    <>
      <FadeAnimation show={Boolean(!training)}>
        <AbsolutePreloader/>
      </FadeAnimation>
      <FadeAnimation show={Boolean(training)}>
        <TrainingContent training={training} isActionsDisabled={isTrainingLoading}/>
      </FadeAnimation>
    </>
  );
};