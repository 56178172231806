import styled from "styled-components";
import {rem} from "../../app/styles";

export const RegisterPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(20)};
    padding: 5%;
    position: relative;
    width: '100%'
`

export const RegisterTitle = styled.h4`
  color: var(--color-text);
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
    margin-bottom: 20px;
`;

export const InputTitle = styled.div`
    color: white;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    line-height: 0;
    margin-top: 10px;
`


export const RegisterFormActions = styled.div`
  display: flex;
  gap: ${rem(25)};
  a {
    color: var(--color-text-dark);
    text-decoration: none;
  }
`

export const InputWrapper = styled.h3`
    display: flex;
    padding-left: ${rem(15)};
    align-content: space-between;
    align-items: center;
    background-color: #2C2C2E;
    border-radius: 10px;
`;

export const SelectWrapper = styled.h3`
    display: flex;
    padding-left: ${rem(15)};
    align-content: space-between;
    align-items: center;
    background-color: #2C2C2E;
    border-radius: 10px;
    
    Select {
        background-color: #2C2C2E;
    }
`;

export const RegisterFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
`