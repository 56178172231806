import {useState} from "react";
import {SwitchboxBtn, SwitchboxWrapper} from "./Switchbox.styles";

export interface ISwitchbox {
  onSelect?: (state: boolean) => void
  isActive?: boolean
}

export const Switchbox = ({onSelect, isActive}: ISwitchbox) => {

  const [isSwitchActive, setIsSwitchActive] = useState<boolean>(isActive)

  const handleSetActive = () => {
    setIsSwitchActive(!isSwitchActive)
    console.log('on select', onSelect)
    onSelect && onSelect(!isSwitchActive)
  }

  return (
    <SwitchboxWrapper isActive={isSwitchActive} onClick={handleSetActive}>
      <SwitchboxBtn
        onClick={handleSetActive}
        isActive={isSwitchActive}
      />
    </SwitchboxWrapper>
  );
};