import { InputTitle, InputWrapper, RegisterPageWrapper, RegisterTitle, SelectWrapper } from './RegisterPage.styles';
import { Input } from "../../ui-kit/Input";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { useForm, Controller } from 'react-hook-form';
import { Button } from "../../ui-kit/Button";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {phoneHandler} from "../../utils/phoneHandler";
import {PhoneInput} from "../AuthorizationPage/Authorization.styles";
import {phoneMask} from "../../utils/phoneMask";
import styled from "styled-components";

// Red circle indicator for required fields
const RequiredIndicator = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: red;
  border-radius: 50%;
  margin-left: 8px;
`;
// Define the TClubVariants type here
type TClubVariants = 540 | 539 | 538;

export const RegisterPage = () => {
    const options = [
        { value: 0, label: 'Выберите...' },
        // { value: 540, label: 'Бутово' },
        { value: 539, label: 'Чехов' },
        { value: 538, label: 'Подольск' }
    ];

    const rem = (px: number) => `${px / 16}rem`;

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            display: 'flex',
            alignContent: 'space-between',
            alignItems: 'center',
            backgroundColor: '#2C2C2E',
            width: '100%',
            borderRadius: '10px',
            borderColor: '#2C2C2E',
            paddingLeft: rem(15),
            fontSize: rem(17),
            fontWeight: 400,
        }),
        menu: (provided: any) => ({
            ...provided,
            backgroundColor: '#2C2C2E',
            width: '100%',
            padding: rem(12),
            borderRadius: '10px',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#FFF',
            borderColor: '#2C2C2E',
            margin: 0,
            padding: rem(12)
        }),
        valueContainer: (provided: any) => ({
            ...provided,
            padding: 0
        }),
        placeholder: (provided: any) => ({
            ...provided,
            color: '#505050',
            margin: 0,
            padding: rem(12),
            borderColor: '#2C2C2E',
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            backgroundColor: state.isFocused ? '#3C3C3E' : '#2C2C2E',
            color: '#FFF',
            paddingLeft: rem(12),
            borderColor: '#2C2C2E',
            borderRadius: '8px',
        }),
        input: (provided: any) => ({
            ...provided,
            color: '#e7e7e7',
            margin: 0,
            paddingLeft: rem(12),
            borderColor: '#2C2C2E'
        }),
        indicatorSeparator: (provided: any) => ({
            display: 'none',
        }),
    };

    // Parse the stored club value from sessionStorage or default to a valid club
    const storedClub = parseInt(sessionStorage.getItem('club') || '0') as TClubVariants;

    const [phone, setPhone] = useState<string>(sessionStorage.getItem('phone') || null);
    const [club, setClub] = useState<TClubVariants>(storedClub);
    const [surname, setSurname] = useState<string>(sessionStorage.getItem('surname') || '');
    const [name, setName] = useState<string>(sessionStorage.getItem('name') || '');
    const [fathername, setFathername] = useState<string>(sessionStorage.getItem('fathername') || '');
    const [email, setEmail] = useState<string>(sessionStorage.getItem('email') || '');
    const [passport, setPassport] = useState<string>(sessionStorage.getItem('passport') || '');
    const [buttonText, setButtonText] = useState('Далее');
    const [buttonDisabled, setButtonDisabled] = useState(true);
    const [phoneError, setPhoneError] = useState(false);

    const { control, handleSubmit, formState: { errors } } = useForm();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if all fields are filled
        const isComplete = phone && club && surname && name && fathername && email && passport;
        // Check if minimum fields for "Skip" are filled
        const isMinimumComplete = club && phone;

        if (isComplete) {
            setButtonText('Далее');
            setButtonDisabled(false);
        } else if (isMinimumComplete) {
            setButtonText('Далее');
            setButtonDisabled(false);
        } else {
            setButtonDisabled(true);
        }
    }, [phone, club, surname, name, fathername, email, passport]);

    const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/;
    const handleGoToShoppingPage = () => {
        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError(true);  // Set error state to true if the phone number is empty
            return;
        }
        // Save data to sessionStorage
        sessionStorage.setItem('club', club.toString());
        sessionStorage.setItem('surname', surname);
        sessionStorage.setItem('name', name);
        sessionStorage.setItem('fathername', fathername);
        sessionStorage.setItem('phone', phone);
        sessionStorage.setItem('email', email);
        sessionStorage.setItem('passport', passport);

        navigate('/shopping');
    }

    // @ts-ignore
    return (
        <RegisterPageWrapper>
            <RegisterTitle>
                Регистрация
            </RegisterTitle>
            <InputTitle>Клуб
                <RequiredIndicator />
            </InputTitle>
            <Select
                required
                placeholder={'Выберите клуб...'}
                options={options}
                styles={customStyles}
                value={options.find(option => option.value === club)}
                onChange={e => {
                    setClub(e?.value as TClubVariants); // Ensure the value is correctly typed
                }}
            />
            <InputTitle>Телефон
                <RequiredIndicator />
            </InputTitle>
            <InputWrapper
                style={{
                    borderColor: phoneError ? 'red' : '#2C2C2E',
                    outline: phoneError ? '1px solid red' : 'none',
                }}>
                <PhoneInput
                    required
                    name={'phone'}
                    inputMode={'numeric'}
                    variant={'primary'} placeholder={'+7 (999) 544-14-14'}
                    onChange={e => {
                        setPhone(e.target.value);
                        setPhoneError(false)
                    }}
                    maskEvent={phoneMask}/>
            </InputWrapper>
            <InputTitle>Фамилия</InputTitle>
            <InputWrapper>
                <Input
                    name={'surname'}
                    variant={'primary'}
                    value={surname}
                    placeholder={'Иванов'}
                    onChange={e => {
                        setSurname(e.target.value);
                        setButtonDisabled(false);
                    }}
                />
            </InputWrapper>
            <InputTitle>Имя</InputTitle>
            <InputWrapper>
                <Input
                    name={'name'}
                    variant={'primary'}
                    value={name}
                    placeholder={'Иван'}
                    onChange={e => {
                        setName(e.target.value);
                        setButtonDisabled(false);
                    }}
                />
            </InputWrapper>
            <InputTitle>Отчество
            </InputTitle>
            <InputWrapper>
                <Input
                    name={'fathername'}
                    variant={'primary'}
                    value={fathername}
                    placeholder={'Иванович'}
                    onChange={e => {
                        setFathername(e.target.value);
                        setButtonDisabled(false);
                    }}
                />
            </InputWrapper>
            <InputTitle>Серия и номер паспорта</InputTitle>
            <InputWrapper>
                <Controller
                    name="passport"
                    control={control}
                    defaultValue={passport}
                    render={({ field }) => (
                        <InputMask
                            {...field}
                            style={{
                                cursor: 'pointer',
                                minHeight: '100%',
                                textAlign: 'left',
                                fontWeight: '400',
                                padding: rem(12),
                                backgroundColor: '#2C2C2E',
                                color: 'white',
                                fontSize: rem(17)
                            }}
                            mask="9999 999999"
                            placeholder="4515 678988"
                            onChange={(e) => {
                                field.onChange(e);
                                setPassport(e.target.value);
                                setButtonDisabled(false);
                            }}
                        />
                    )}
                />
            </InputWrapper>
            <InputTitle>Почта</InputTitle>
            <InputWrapper>
                <Input
                    name={'email'}
                    variant={'primary'}
                    value={email}
                    placeholder={'example@mail.ru'}
                    onChange={e => {
                        setEmail(e.target.value);
                        setButtonDisabled(false);
                    }}
                />
            </InputWrapper>
            <MainButton
                text={buttonText}
                disabled={buttonDisabled}
                color={!buttonDisabled ? "#F5C71A" : "#E7E7E7"}
                textColor={"#000000"}
                onClick={handleGoToShoppingPage}
            />
            <Button variant={'filled'} disabled={buttonDisabled} onClick={handleGoToShoppingPage}>
                {buttonText}
            </Button>
        </RegisterPageWrapper>
    );
};