import {useMemo, useState} from "react";
import {
  FiltersPageLayout,
  FiltersTitle,
  FilterVariant, FilterVariantWrapper,
  TrainingFilterWrapper,
  VariantsWrapper,
} from "./FiltersPage.styles";
import {ReactComponent as CheckboxIcon} from "./checkbox.svg";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setTimeFromFilter} from "../Schedule/schedule.slice";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {useNavigate} from "react-router-dom";
import {FiltersLayout} from "../../app/layouts";
import {BackButton} from "@vkruglikov/react-telegram-web-app";

// Функция для форматирования времени в формат HH:mm
function formatTime(hours: number, minutes: number): string {
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  return `${formattedHours}:${formattedMinutes}`;
}

function generateTimeSlots(): string[] {
  const timeSlots: string[] = [];
  const startTimeHours = 8;
  const endTimeHours = 24;
  const intervalMinutes = 60;

  for (let hours = startTimeHours; hours <= endTimeHours; hours++) {
    for (let minutes = 0; minutes < 60; minutes += intervalMinutes) {
      if (hours === endTimeHours && minutes === 0) {
        break;
      }
      const formattedTime = formatTime(hours, minutes);
      timeSlots.push(formattedTime);
    }
  }

  return timeSlots;
}

export const TimeFromFilter = () => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const variants = useMemo(() => generateTimeSlots(), []);

  const handleGetBack = () => {
    navigate(-1);
  };

  const handleSelectOption = (option: string) => {
    if (selectedOptions.includes(option)) {
      const filteredOptions = selectedOptions.filter((opt) => opt !== option);
      setSelectedOptions(filteredOptions);
    } else setSelectedOptions([...selectedOptions, option]);
  };

  const handleSubmitFilters = () => {
    dispatch(setTimeFromFilter(selectedOptions));
    navigate("/");
  };

  return (
    <FiltersLayout onClick={handleSubmitFilters}>
      <FiltersPageLayout>
        <BackButton onClick={handleGetBack}/>
        <TrainingFilterWrapper>
          <FiltersTitle>Начало занятия</FiltersTitle>
          <VariantsWrapper>
            {variants.map((variant, index) => (
                <FilterVariantWrapper
                    onClick={(e) => handleSelectOption(variant)}
                    key={index}
                >
                  <FilterVariant>
                    {variant}
                  </FilterVariant>
                  {selectedOptions.includes(variant) && <CheckboxIcon />}
                </FilterVariantWrapper>
            ))}
          </VariantsWrapper>
        </TrainingFilterWrapper>
      </FiltersPageLayout>
    </FiltersLayout>
  );
};
