export const getDatesFromResponse = (data: Object) => {
    const today = new Date()
    const formattedToday = today.toISOString().split('T')[0]
    const datesArr = []

    for (let key in data) {
        datesArr.push(key)
    }

    if(data?.toString().length === 0) {
        datesArr.push(formattedToday)
    }

    return datesArr
}