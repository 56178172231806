import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IProfile } from "../../components/ProfilePage/ProfilePage";

export type TClubVariants = 'Бутово' | 'Чехов' | 'Подольск'

type User = {
    userName: string
    name: string
    lastName: string
    phone: string
    club: string
    appointed: string[]
    profile: IProfile
}

const initialState: User = {
    userName: '',
    name: '',
    lastName: '',
    phone: '',
    club: 'Чехов',
    appointed: [],
    profile: {
        Status: '',
        ClientID: '',
        Name: '',
        SecondName: '',
        LastName: '',
        Birthday: '',
        Sex: 0,
        Email: '',
        Card: {
            EndDate: '',
            Status: '',
        },
        EditAllowed: false,
        AppointmentsToRate: '',
        Avatar: ''
    },
}

const userSlice = createSlice({
    name: 'schedule',
    initialState: initialState,
    reducers: {
        registerUser(state, action: PayloadAction<Pick<User, 'phone' | 'club'>>) {
            state.phone = action.payload.phone
            state.club = action.payload.club
        },
        authorizeUser(state, action: PayloadAction<Pick<User, 'phone'>>) {
            state.phone = action.payload.phone
        },
        setUserName(state, action: PayloadAction<Pick<User, 'userName'>>) {
            state.userName = action.payload.userName
        },
        logout(state) {
            state.phone = ''
        },
        setAppointment(state, action: PayloadAction<string>) {
            state.appointed.push(action.payload)
        },
        cancelAppointment(state, action: PayloadAction<string>) {
            state.appointed.push(action.payload)
        },
        setUserProfile(state, action: PayloadAction<IProfile>) {
            state.profile = action.payload
        }
    },
});

export const { authorizeUser, registerUser, setUserName, logout, setAppointment, cancelAppointment, setUserProfile } = userSlice.actions;

export default userSlice.reducer;