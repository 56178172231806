import {logout} from "../app/store/userSlice";
import axios from "axios";
import {useAppDispatch} from "./useAppDispatch";
import {useNavigate} from "react-router-dom";

export const useSendToAuth = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    return function sendToAuth () {
        dispatch(logout)
        localStorage.clear()
        axios.defaults.headers.common['Authorization'] = ``
        navigate('/')
        window.location.reload()
    }
}