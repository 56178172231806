import {ChangeEvent, PropsWithChildren} from "react";
import {ButtonLoader, ButtonWrapper} from "./Button.styles";

export type IButtonVariants = 'primary' | 'filled' | 'disabled' | 'cancel-primary' | 'cancel-secondary' | 'cancel-third'

export interface IButtonProps {
    variant: IButtonVariants
    onClick?: (e: ChangeEvent<HTMLButtonElement>) => void
    disabled?: boolean
    className?: string
    isLoading?: boolean
}

export const Button = ({variant, onClick, children, disabled, className, isLoading}: IButtonProps & PropsWithChildren) => {

    const handleClick = (e: ChangeEvent<HTMLButtonElement>) => {
        if(disabled) {
            e.preventDefault()
            e.stopPropagation()
        }
        onClick && onClick(e)
    }

    return (
        <ButtonWrapper
            variant={variant}
            onClick={handleClick}
            disabled={disabled}
            className={className}
        >
            {isLoading && <ButtonLoader />}
            {children}
        </ButtonWrapper>
    );
};