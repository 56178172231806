import styled, { css } from "styled-components";
import { rem } from "../../app/styles";
// @ts-ignore
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";

interface ITitleStatus {
  status: string
}

export const ProfilePageWrapper = styled.div`
  width: 100%;
  padding: 22% 5% 5% 5%;
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  position: relative;
  
  Button {
    margin-top: 15%;
  }
`

export const ProfilePageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const UserCreds = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(15)};
`

export const UserName = styled.p`
  color: var(--color-text);
  font-size: 32px;
  font-weight: 800;
  line-height: 43px;
  max-width: 50%;
`

export const UserPicWrapper = styled.div`
  position: relative;
  width: ${rem(100)};
  height: ${rem(100)};
  display: flex;
  padding: ${rem(8)};
  border: 2px solid var(--color-warning);
  align-items: center;
  border-radius: ${rem(50)};
`

export const UserPicPlaceholder = styled.div`
  img {
    filter: invert(88%) sepia(100%) saturate(3%) hue-rotate(192deg) brightness(107%) contrast(100%);
    width: ${rem(80)};
    height: ${rem(80)};
  }
  `

export const UserPic = styled.div`
    width: ${rem(70)};
    height: ${rem(70)};
    border-radius: 50%;
    overflow: hidden;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 50%;
    padding: ${rem(10)}
  }
`

export const UserPicImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  border-radius: 50%;
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(5)};
  padding: ${rem(40)} ${rem(30)};
  border-left: 1px solid var(--color-text-light);
`

export const UserInfoTitle = styled.p<ITitleStatus>`
  font-size: 11px;
  font-weight: 400;
  line-height: 13px;
  text-align: left;

${({ status }) => {
    switch (status) {
      case 'Active':
        return css`
            color: var(--color-action);
        `
      case 'NotActive':
        return css`
          color: var(--color-text);
        `
      case 'Frozen':
        return css`
          color: var(--color-text);
        `
      case 'Locked':
        return css`
          color: var(--color-cancel);
        `
      case 'Closed':
        return css`
          color: var(--color-cancel);
        `
    }
  }}
`

export const UserInfoField = styled.p`
  color: var(--color-text);
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  text-align: left;

`

export const UserMenu = styled.div`
  display: flex;
  flex-direction: column;
`

export const UserMenuItem = styled.p`
  color: var(--color-text);
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  padding: ${rem(20)} ${rem(15)};
  border-bottom: 1px solid var(--color-text-light);
  display: flex;
  justify-content: space-between;
  
  &:first-child {
    border-top: 1px solid var(--color-text-light);
  }

  svg {
    width: ${rem(24)};
    height: ${rem(24)};
  }
`

export const LogoutAction = styled(UserMenuItem)`
  color: var(--color-cancel)
`

export const StyledDatePicker = styled(DatePicker)`
  border-bottom: var(--color-action) 1px solid;
  //border-top: none;
  //border-right: none;
  //border-left: none;
  border-radius: 0;
  text-decoration: #F5C71A underline 0.7px;
  background: transparent;
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  min-height: ${rem(30)};
  width: 100%;
  display: flex;
`

export const FreezeDatesWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
  align-items: center;
`

export const FreezeDatesTypography = styled.p`
  display: flex;
  font-size: ${rem(16)};
  color: var(--color-text)
`