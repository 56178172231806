export const phoneHandler = (phone: string) => {
    if(phone.startsWith('8')) {
        return phone.replace('8', '7')
            .replace('+', '')
            .replace('(', '')
            .replace(')', '')
            .replaceAll('-', '')
            .replaceAll(' ', '')
    } else return phone
        .replace('+', '')
        .replace('(', '')
        .replace(')', '')
        .replaceAll('-', '')
        .replaceAll(' ', '')
}