import {IScheduleTraining} from "../TrainingCard/model/TrainingCard.types";
import {ScheduleCardWrapper, ScheduleDate, ScheduleWrapper,} from "../Schedule/Schedule.styles";
import TrainingCard from "../TrainingCard/TrainingCard";
import {useNavigate} from "react-router-dom";
import {useOnScreen} from "../../hooks/useOnScreen";
import {memo, useEffect, useMemo, useRef} from "react";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useAppSelector} from "../../hooks/useAppSelector";
import {useScrollElementIntoView} from "../../hooks/useScrollElementIntoView";
import {setIsTransition, setScheduleInView} from "../Schedule/schedule.slice";

interface IProps {
  date: string;
  root: any;
  trainings: IScheduleTraining[] | undefined;
}

export const ScheduleDateBlock = memo(({date, trainings, root}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const isTransition = useAppSelector((state) => state.schedule.isTransition);
  const schedulesInView = useAppSelector(
    (state) => state.schedule.schedulesInView,
  );
  const animation = useAppSelector((state) => state.schedule.animation);

  const dateTitleRef = useRef<HTMLDivElement>(null);

  const isCurrentDateOnScreen = useOnScreen(dateTitleRef, {
    rootMargin: `-230px 0px -120px 0px`,
    root
  }, false, true, (value) => {

  });

  useEffect(() => {
    if (isTransition === date) {
      console.log('current transition is for current date', date, isTransition)
      dispatch(setIsTransition(undefined));
      dispatch(setScheduleInView({date, isInView: true}));
      return;
    }

    console.log('current transition is not for current date', date, isCurrentDateOnScreen)
    dispatch(setScheduleInView({date, isInView: isCurrentDateOnScreen}));
  }, [isCurrentDateOnScreen]);

  const currentDateTitle = useMemo(
    () =>
      new Date(date).toLocaleDateString("ru-RU", {
        day: "2-digit",
        month: "long",
      }),
    [date],
  );

  const [scrollIntoViewHeaderRef, scrollHeaderIntoViewCb] =
    useScrollElementIntoView<HTMLParagraphElement>();

  const handleGetTrainingPage = (id: string) => {
    navigate(`/schedule/${id}`);
  };


  useEffect(() => {
    console.log('mount')
  }, []);

  return (
    <ScheduleWrapper ref={dateTitleRef} id={`schedule-block-${date}`}>
      <ScheduleDate>
        {currentDateTitle}
      </ScheduleDate>
      {trainings && (
        <ScheduleCardWrapper>
          {trainings.map(
            ({
               Service,
               StartDate,
               preEntry,
               AppointmentID,
               Duration,
               Employee,
               isSigned,
               isConducted,
                Commercial
             }) => (
              <TrainingCard
                isConducted={isConducted}
                title={Service.Title}
                start_time={`${StartDate.slice(11)}`}
                duration={Duration}
                trainer={Employee.FullName}
                isCancelled={preEntry}
                id={AppointmentID}
                onClick={() => handleGetTrainingPage(AppointmentID)}
                isSigned={isSigned}
                Commercial={Commercial}
              />
            ),
          )}
        </ScheduleCardWrapper>
      )}
      {!trainings && (
        <ScheduleCardWrapper>
          <p>Тренировки на выбранную дату не найдены</p>
        </ScheduleCardWrapper>
      )}
    </ScheduleWrapper>
  );
});
