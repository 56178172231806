import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IScheduleTraining } from "../TrainingCard/model/TrainingCard.types";
import { IClub } from "../FiltersPage/ClubFilters";

type Schedule = {
  title: string[];
  employee: string[];
  selectedClub: IClub;
  startTime: string;
  endTime: string;
  schedule: IScheduleTraining[];
  schedulesInView: Record<string, { date: string; isInView: boolean }>;
  selectedDate: string;
  timeFrom: string[];
  isTransition: string | undefined;
  animation: boolean;
};

const initialState: Schedule = {
  title: [],
  employee: [],
  selectedClub: { id: 0, title: '' },
  startTime: "",
  endTime: "",
  schedulesInView: {},
  schedule: [],
  selectedDate: "",
  timeFrom: [],
  isTransition: '',
  animation: false
};

const scheduleSlice = createSlice({
  name: "schedule",
  initialState: initialState,
  reducers: {
    setTrainingFilter(state, action: PayloadAction<string[]>) {
      state.title = action.payload;
    },
    setTrainerFilter(state, action: PayloadAction<string[]>) {
      state.employee = action.payload;
    },
    setClubFilter(state, action: PayloadAction<IClub>) {
      state.selectedClub = action.payload
    },
    setTimeFromFilter(state, action: PayloadAction<string[]>) {
      state.timeFrom = action.payload;
    },
    setStartTimeFilter(state, action: PayloadAction<string>) {
      state.startTime = action.payload;
    },
    setEndTimeFilter(state, action: PayloadAction<string>) {
      state.endTime = action.payload;
    },
    resetFilters(state) {
      state.title = [];
      state.employee = [];
      state.startTime = "";
      state.endTime = "";
    },
    setSelectedDate(state, action: PayloadAction<string>) {
      state.selectedDate = action.payload;
    },
    setScheduleInView(
      state,
      action: PayloadAction<{ date: string; isInView: boolean }>,
    ) {
      const { date, isInView } = action.payload;
      state.schedulesInView[date] = { date, isInView };
    },
    setIsTransition(state, action: PayloadAction<string | undefined>) {
      console.log('set is transition to', action.payload)
      state.isTransition = action.payload;
    },
    setAnimation(state, action: PayloadAction<boolean>) {
      console.log('set is animation to', action.payload)
      state.animation = action.payload;
    }
  },
});

export const {
  setEndTimeFilter,
  setStartTimeFilter,
  setClubFilter,
  setTrainerFilter,
  setTrainingFilter,
  resetFilters,
  setSelectedDate,
  setScheduleInView,
  setTimeFromFilter,
  setIsTransition,
  setAnimation
} = scheduleSlice.actions;

export default scheduleSlice.reducer;
