import styled, {css} from "styled-components";
import {rem} from "../../app/styles";

interface ISwitchboxElement {
    isActive?: boolean
}

export const SwitchboxWrapper = styled.div<ISwitchboxElement>`
  background: var(--color-bg);
  padding: ${rem(0)} ${rem(2)};
  display: flex;
  align-items: center;
  width: ${rem(45)};
  height: ${rem(25)};
  border-radius: ${rem(16)};
  transition: 0.3s ease-in;
  
  ${({isActive}) => isActive &&
    css`
      background: var(--color-action);
    `
}
`

export const SwitchboxBtn = styled.button<ISwitchboxElement>`
  width: ${rem(22)};
  height: ${rem(22)};
  border-radius: ${rem(12)};
  background: var(--color-text);
  transition: 0.2s ease-in;

  ${({isActive}) => isActive &&
          css`
      background: var(--color-primary);
      align-items: flex-end;
            transform: translateX(90%);
    `
  }
`