import { ChangeEvent } from 'react'
import { getInputNumbersValue } from './getInputNumbersValue'

export const phoneMask = (e: ChangeEvent<HTMLInputElement>) => {
	const input = e.target
	let inputNumbersValue = getInputNumbersValue(input.value)
	const selectionStart = input.selectionStart
	let formattedInputValue = ''

	if (!inputNumbersValue) {
		input.value = null; // Set input value to null when it's empty
		input.maxLength = 18; // Reset the maxLength attribute
		return;
	}

	if (input.value.length != selectionStart) {
		if (input.value && /\D/g.test(input.value)) {
			input.value = inputNumbersValue
		}
		return
	}

	inputNumbersValue = inputNumbersValue.replace(/^8/, '7');

	if (['7', '8', '9'].indexOf(inputNumbersValue[0]) > -1) {
		if (inputNumbersValue[0] == '9') inputNumbersValue = '7' + inputNumbersValue
		const firstSymbols = inputNumbersValue[0] == '7' ? '+7' : '+7'
		formattedInputValue = input.value = firstSymbols + ' '
		if (inputNumbersValue.length > 1) {
			formattedInputValue += '(' + inputNumbersValue.substring(1, 4)
		}
		if (inputNumbersValue.length >= 5) {
			formattedInputValue += ') ' + inputNumbersValue.substring(4, 7)
		}
		if (inputNumbersValue.length >= 8) {
			formattedInputValue += '-' + inputNumbersValue.substring(7, 9)
		}
		if (inputNumbersValue.length >= 10) {
			formattedInputValue += '-' + inputNumbersValue.substring(9, 11)
		}
	} else {
		formattedInputValue = '+' + inputNumbersValue.substring(0, 16)
	}
	input.value = formattedInputValue;
	input.maxLength = 18;
}