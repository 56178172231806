import axios from "axios";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useEffect, useState} from "react";
import {authorizeUser} from "../../app/store/userSlice";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {
  AppLogo,
  AppTitle,
  AuthorizationContent,
  AuthorizationFormActions,
  AuthorizationFormWrapper,
  AuthorizationTitle,
  AuthorizationTypography,
  OtpVerificationInfo,
  OtpVerificationWrapper,
  PhoneInput
} from './Authorization.styles'
import {useNavigate} from "react-router-dom";
import {Button} from "../../ui-kit/Button";
import {OtpInput} from "../../ui-kit/OtpInput";
import {ReactComponent as LogoSvg} from './logo.svg'
import {ReactComponent as ShevronRight} from './chevron-right.svg'
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {phoneMask} from "../../utils/phoneMask";
import {phoneHandler} from '../../utils/phoneHandler'
import ym from "react-yandex-metrika";

//@ts-ignore
const tg = window.Telegram.WebApp
console.log('tg', tg)
// const {MainButton} = tg

const AuthorizationForm = () => {
  const [otp, setOtp] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const handleChange = (value: string) => setEnteredOtp(value);
  const [enteredOtp, setEnteredOtp] = useState('');
  const [resendTime, setResendTime] = useState(0); // Countdown timer value in seconds
  const [isResendActive, setIsResendActive] = useState(false); // Flag to indicate whether the countdown is active
  const [isOtpSent, setIsOtpSent] = useState(false); // Flag to indicate whether OTP is already sent
  const [isOtpReSent, setIsOtpReSent] = useState(false); // Flag to indicate whether OTP is already sent
  const userId = tg?.initDataUnsafe?.user?.id

  useEffect(() => {
    if (resendTime > 0 && isResendActive) {
      const timer = setInterval(() => {
        setResendTime(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (resendTime === 0 && isResendActive) {
      setIsResendActive(false);
    }
  }, [resendTime, isResendActive]);

  async function handleLogIn(phone: string, userId: string) {
    try {
      const result = await axios.post('https://api.profitnes.club/api/login', {
        phone: phone,
        telegram_chat_id: userId
      });

      if (result.data.data.pincode) {
        setOtp(result.data.data.pincode);
        setIsResendActive(true); // Start countdown timer
        setResendTime(60); // Set countdown timer value to 60 seconds
      } else {
        dispatch(authorizeUser({phone: phone}));
        localStorage.setItem('token', result.data.data.token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        navigate('/');
      }
    } catch (error) {
      if (error.response && error.response.status === 500) {
        tg.showAlert('Внутренняя ошибка! Сообщите в поддержку бота');
      } else if (error.response && error.response.status === 400) {
        const responseData = error.response.data;
        if (responseData.error_type === 1) {
          const ticketNumber = String(responseData.ticket);
          tg.showAlert(`Ошибка! Проверьте правильность введенного номера. Информация передана в отдел продаж. \nНомер вашего тикета: #${ticketNumber}`);
        } else if (responseData.error_type === 1) {
          tg.showAlert('Внуренняя ошибка, информация передана команде разработчиков.')
        }
      } else {
        // Handle other error cases
        console.error(error);
      }
    }
  }

  async function handleSendOtp(otp: string, phone: string) {
    const clubId = localStorage.getItem('club')
    if (otp === enteredOtp) {
      try {
        console.log(otp)
        const result = await axios.post('https://api.profitnes.club/api/verify-phone',
          {phone}).then((res) => {
          dispatch(authorizeUser({phone: phone}))
          localStorage.setItem('token', res.data.data.token)
          localStorage.setItem('club', res.data.data.club_id)
          axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
          navigate('/schedule')
          const clubId = localStorage.getItem('club')
          ym('reachGoal', `successful_login_${clubId}`)
        })
      } catch (e) {
        tg.showAlert('Что-то пошло не так..')
        ym('reachGoal', `login_error_${clubId}`)
        // console.log('Что-то пошло не так..')
      }
    } else {
      tg.showAlert('Неверный код')
      // console.log('Неверный код')
    }
  }

  async function handleResendOtp() {
    try {
      const result = await axios.post('https://api.profitnes.club/api/login',
        {
          phone: phoneHandler(phone),
          telegram_chat_id: userId
        }).then((res) => {
          if (res.data.data.pincode) {
            setOtp(res.data.data.pincode);
            setIsResendActive(true); // Start countdown timer
            setResendTime(0); // Set countdown timer value to 60 seconds
            setIsOtpReSent(true);
          }
        }
      )
    } catch (e) {
      // Handle error
    }
  }

  const handleRegister = () => {
    navigate('/register')
  }

  const handleGetBack = () => {
    setOtp('');
  }

  return (
    otp.length < 1 ? (
      <AuthorizationFormWrapper>
        <AppLogo>
          <LogoSvg/>
        </AppLogo>
        <AuthorizationContent>
          <AuthorizationTitle>
            Вход
          </AuthorizationTitle>
          <AuthorizationFormActions>
            <PhoneInput
              inputMode={'numeric'}
              variant={'primary'} placeholder={'Введите номер телефона'} onChange={e => setPhone(e.target.value)}
              maskEvent={phoneMask}/>
            <Button disabled={!phone} variant={'filled'}
                    onClick={async () => await handleLogIn(phoneHandler(phone), userId)}>
              <ShevronRight/>
            </Button>
          </AuthorizationFormActions>
          <p style={{ fontWeight: 'bold', color: '#F5C71A'}} onClick={handleRegister}>Нет абонемента?</p>
        </AuthorizationContent>
      </AuthorizationFormWrapper>
    ) : (
      <>
        <BackBtn onClick={handleGetBack}>
          <BackIcon/>
        </BackBtn>
        <OtpVerificationWrapper>
          <OtpVerificationInfo>
            <AppTitle>ВЕРИФИКАЦИЯ</AppTitle>
            <AuthorizationTypography>
              На указанный вами телефон поступит звонок. Введите <b>4 последние цифры</b> телефона, с которого поступит
              звонок
            </AuthorizationTypography>
          </OtpVerificationInfo>
          <OtpInput value={enteredOtp} valueLength={4} onChange={handleChange}/>
          {resendTime > 0 ? (
            <p className={'text'}>Отправить повторный звонок
              через: {String(Math.floor(resendTime / 60)).padStart(2, '0')}:{String(resendTime % 60).padStart(2, '0')}</p>
          ) : (
            isOtpReSent ? (
              <p className={'text'}>Звонок отправлен</p>
            ) : (
              <p className={'action'}><a onClick={handleResendOtp}>Отправить звонок повторно</a></p>
            )
          )}
          <MainButton
              text={"Применить"}
              disabled={!Boolean(enteredOtp.length === 4)}
              color={"#F5C71A"}
              textColor={"#000000"}
              onClick={async () => await handleSendOtp(otp, phoneHandler(phone))}
          />
          {/*<Button variant={'filled'} disabled={!Boolean(enteredOtp.length === 4)}*/}
          {/*        onClick={async () => await handleSendOtp(otp, phoneHandler(phone))}>Далее*/}
          {/*</Button>*/}
        </OtpVerificationWrapper>
      </>
    )
  );
};


export default AuthorizationForm;