import { useNavigate } from "react-router-dom";
import { FooterWrapper, NavIcon } from "./Footer.styles";
import { ReactComponent as AvatarIcon } from './user_footer.svg'
import { ReactComponent as Schedule } from './schedule.svg'
import { ReactComponent as Home } from './home.svg'
import { useAppSelector } from "../../hooks/useAppSelector";
import { useEffect } from "react";
import { ERoutes } from "../../app/AppRouter.types";
import { setUserProfile } from "../../app/store/userSlice";
import axios from "axios";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {UserPicImage} from "../ProfilePage/ProfilePage.styles";

const Footer = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const { Avatar } = useAppSelector(state => state.user.profile)

    const handleGoToProfile = () => {
        navigate('/profile')
    }

    const handleGoToSchedule = () => {
        navigate('/schedule')
    }

    const handleGoToHomePage = () => {
        navigate('/my_trainings')
    }

    const currentUrl = window.location.href


    async function getProfile() {
        const res = await axios.get('https://api.profitnes.club/api/view-profile')
            .then((res) => {
                dispatch(setUserProfile(res?.data))
            })
            .catch((err) => {
                if (err?.response?.status === 401) {
                    navigate('/')
                } else if (err?.response?.status === 500) {
                    sendToAuth()
                }

                const stringErrorStatus = err?.response?.status.toString()

                if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
                    navigate(ERoutes.MAIN)
                }
            })
    }

    useEffect(() => {
        getProfile();
    }, []);

    return (
        <FooterWrapper>
            <NavIcon isSelected={currentUrl.endsWith('/schedule')} onClick={handleGoToSchedule}>
                <Schedule />
            </NavIcon>
            <NavIcon isSelected={currentUrl.endsWith('/my_trainings')} onClick={handleGoToHomePage}>
                <Home />
            </NavIcon>
            <NavIcon isSelected={currentUrl.endsWith('/profile')} onClick={handleGoToProfile}>
                {Avatar ? <UserPicImage src={Avatar} /> : <AvatarIcon />}
            </NavIcon>
        </FooterWrapper>
    );
};

export default Footer;

function sendToAuth() {
    throw new Error("Function not implemented.");
}
