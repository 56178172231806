import {configureStore} from "@reduxjs/toolkit";
import userSlice from "./userSlice";
import scheduleSlice from "../../components/Schedule/schedule.slice";

const store = configureStore({
    reducer: {
        user: userSlice,
        schedule: scheduleSlice
    }
})

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;