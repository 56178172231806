import styled from "styled-components";
import { rem } from "../styles";
import { Button } from "../../ui-kit/Button";

export const FiltersLayoutWrapper = styled.div`
  padding-bottom: ${rem(120)};
`;

export const FiltersLayoutButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(40)};
  border-top: var(--color-third) 1px solid;
  background: var(--color-primary);
`;

export const FiltersLayoutButton = styled(Button)`
  min-width: ${rem(270)};
`;
