import styled, {css} from "styled-components";
import {rem} from "../../app/styles";
import {Button} from "../../ui-kit/Button";

interface IAvailable {
  isConducted?: boolean
}


export const TrainingPageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrainingPageMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
`;

export const TrainingPageAvailable = styled.div<IAvailable>`
  background: var(--color-action);
  color: var(--color-primary);
  border-radius: ${rem(5)};
  max-height: ${rem(16)};
  padding: ${rem(1)} ${rem(5)};
  text-align: center;
  font-size: ${rem(11)};
  font-weight: 700;
  line-height: ${rem(13)};
  white-space: nowrap;

  ${({isConducted}) => isConducted &&
    css`
      background: var(--color-warning);
      padding-bottom: ${rem(7)};
    `
  }
`;

export const TrainingPageTitle = styled.p`
  color: var(--color-text);
  font-size: ${rem(20)};
  font-weight: 600;
  line-height: ${rem(25)};
`;

export const TrainingPageContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${rem(20)};
  border-top-left-radius: ${rem(35)};
  border-top-right-radius: ${rem(35)};
  background: var(--color-action);
  padding: ${rem(30)};
  background: var(--color-primary);
  margin-top: ${rem(220)};
`;

export const TrainingPageImg = styled.img`
  width: 100%;
  height: ${rem(250)};
  margin-bottom: ${rem(-20)};
  object-fit: cover;

  // @media (max-width: 425px) {
  //   /* Styles for devices up to 425px wide (e.g., iPhone SE) */
  //   height: auto;
  //   max-width: 100%;
  //   object-fit: scale-down;
  // }
  //
  // @media (min-width: 426px) and (max-width: 768px) {
  //   /* Styles for devices between 426px and 768px wide (e.g., larger phones) */
  //   height: auto;
  //   max-width: 100%;
  //   object-fit: scale-down;
  // }
  //
  // @media (min-width: 769px) and (max-width: 1024px) {
  //   /* Styles for devices between 769px and 1024px wide (e.g., iPad portrait) */
  //   height: auto;
  //   max-width: 100%;
  //   object-fit: scale-down;
  // }
  //
  // @media (min-width: 1025px) and (max-width: 1440px) {
  //   /* Styles for devices between 1025px and 1440px wide (e.g., larger tablets) */
  //   height: auto;
  //   max-width: 100%;
  //   object-fit: scale-down;
  // }
  //
  // @media (min-width: 1441px) {
  //   /* Styles for devices wider than 1440px (e.g., desktops) */
  //   height: ${rem(250)};
  //   max-width: none;
  //   object-fit: scale-down;
`;

export const TrainingPageTrainerRoomBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TrainerInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TrainingAvatarWrapper = styled.div`
  position: relative;
  width: ${rem(32)};
  height: ${rem(32)};
  display: flex;
  border: 1px solid var(--color-warning);
  align-items: center;
  border-radius: ${rem(50)};
  justify-content: center; /* Center the content horizontally */
`;

export const TrainerAvatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensure the image covers the entire container */
  border-radius: 50%;
`;
export const TrainingPageTrainer = styled.div`
  color: var(--color-action);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(15)};
  margin-left: 10px;
  justify-content: center;
  
`;

export const TrainingPageRoom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-text);
  line-height: ${rem(15)};
  font-size: ${rem(13)};
  font-weight: 400;
`;

export const TrainingPageInfo = styled.div`
  display: flex;
  width: 100%;
  background: var(--color-bg);
  color: var(--color-text);
  padding: ${rem(20)};
  border-radius: ${rem(20)};
  align-items: center;

  p {
    flex: 1 1 33%;
    align-content: center;
    padding: ${rem(10)};
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: ${rem(22)};
    font-weight: 600;
    line-height: ${rem(28)};

    &:first-child {
      border-right: var(--color-third) solid 1px;
    }

    &:last-child {
      border-left: var(--color-third) solid 1px;
    }

    span {
      font-size: ${rem(11)};
      font-style: normal;
      font-weight: 400;
      line-height: ${rem(13)};
    }
  }
`;

export const TrainingPageDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  padding: ${rem(15)};
  background: var(--color-bg);
  align-self: center;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
  width: 100%;

  p {
    font-size: ${rem(17)};
    font-weight: 700;
  }
`;

export const TrainingPageBtn = styled(Button)`
  position: fixed;
  bottom: 2%;
  align-self: center;
  margin-top: ${rem(40)};
`;

export const BackBtn = styled.div`
  position: fixed;
  left: 3%;
  top: 7%;

  svg {
    width: ${rem(32)};
    height: ${rem(32)};
  }
`;

export const TrainingBackBtn = styled(Button)<{ hidden: boolean }>`
  display: flex;
  gap: ${rem(5)};
  align-items: center;
  justify-content: center;
  width: 135px;

  position: fixed;
  left: 3%;
  top: 7%;
  border-radius: 30px;
  padding: 10px;

  svg {
    width: ${rem(25)};
    height: ${rem(25)};
  }

  ${({hidden}) => hidden && css`
    width: 45px;
  `}

  transition: width 0.3s ease;
`

export const TrainingBackText = styled.span<{ hidden: boolean }>`
  font-size: ${rem(14)};
`
export const TrainingPageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: ${rem(30)};
  position: relative;

  ${TrainingPageBtn} {
    width: 75%;
  }
`;

export const DisabledBellButton = styled.div`
  position: fixed;
  bottom: ${rem(10)};
  right: 10px;
  background-color: var(--color-text);
  border: 3px solid var(--color-primary);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  display: flex; /* Add flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
    `

export const TrainingPageDescHeader = styled.div`
   display: flex;
   flex-direction: row;
  justify-content: space-between;
  gap: ${rem(10)};
  // padding: ${rem(15)};
   background: var(--color-bg);
   align-self: center;
   color: var(--color-text);
  font-size: ${rem(13)};
   font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
   width: 100%;

  p {
    font-size: ${rem(17)};
    font-weight: 700;
  }
`;

export const TrainingCommercialLabel = styled.div`
    background: var(--color-action);
    color: var(--color-primary);
    border-radius: ${rem(5)};
    max-height: ${rem(16)};
    padding: ${rem(1)} ${rem(5)};
    text-align: center;
    font-size: ${rem(11)};
    font-weight: 700;
    line-height: ${rem(13)};
    white-space: nowrap;
    align-self: end;
`