import React from 'react';
import {
    RubleWrapper,
    TrainingCardCommercialMark,
    TrainingCardPrimaryField,
    TrainingCardPrimaryTime,
    TrainingCardSignedMark,
    TrainingCardTitle,
    TrainingCardTrainer,
    TrainingCardWrapper,
    TrainingsCardFields
} from "./TrainingCard.styles";
import {ITrainingCard} from "./model/TrainingCard.types";
import {symbolsCutter} from "../../utils";
import {ReactComponent as CommercialIcon} from "./ruble_white.svg";
import {ReactComponent as RubleWhite} from "./ruble_white.svg";
import {ReactComponent as RubleActive} from "./ruble_active.svg";
import {rem} from "../../app/styles";

const TrainingCard = ({
                        title,
                        start_time,
                        isCancelled,
                        trainer,
                        duration,
                        onClick,
                        isSigned,
                        isConducted,
                        Commercial
                      }: ITrainingCard) => {
  return (
    <TrainingCardWrapper isCancelled={isCancelled} onClick={onClick}>
      <TrainingsCardFields>
        <TrainingCardTitle>
          <p>{symbolsCutter(title, 18)}</p>
        </TrainingCardTitle>
        {/*<TrainingCardSquareWrapper>*/}
        {/*    <TrainingCardSquare />*/}
        {/*</TrainingCardSquareWrapper>*/}
          {!Commercial ? (
              <TrainingCardSignedMark
                  isSigned={isSigned}
                  isConducted={isConducted}
                  isCommercial={Commercial}
              />
          ) : (
              <>
              {isSigned ? (
                      <RubleWrapper isSigned={isSigned} isConducted={isConducted}>
                          <RubleActive style={{width: rem(20), height: rem(10)}}/>
                      </RubleWrapper>
                  ) : (
                      <RubleWrapper isSigned={isSigned} isConducted={isConducted}>
                          <RubleWhite style={{width: rem(20), height: rem(10)}}/>
                      </RubleWrapper>
                  )
              }
              </>
          )}
          </TrainingsCardFields>
      <TrainingCardPrimaryTime>{start_time}</TrainingCardPrimaryTime>
        {/*<TrainingCardCommercialMark isCommercial={Commercial}>*/}
        {/*    /!*<CommercialIcon style={{ display: Commercial ? "block" : "none"}}/>*!/*/}
        {/*</TrainingCardCommercialMark>*/}
      <TrainingsCardFields>
        <TrainingCardTrainer>{trainer}</TrainingCardTrainer>
        <TrainingCardPrimaryField>{duration} мин</TrainingCardPrimaryField>
      </TrainingsCardFields>
    </TrainingCardWrapper>
  );
};

export default TrainingCard;