import {Switchbox} from "../../ui-kit/SwitchBox";
import {UserMenu, UserMenuItem} from "../ProfilePage/ProfilePage.styles";
import {UserSettingsTitle, UserSettingsWrapper} from "./SettingsPage.styles";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {useNavigate} from "react-router-dom";
import {BackButton} from "@vkruglikov/react-telegram-web-app";

export const SettingsPage = () => {
  const navigate = useNavigate()

  const shouldNotifyOnTrainings = JSON.parse(localStorage.getItem('shouldNotifyOnTrainings'))
  const shouldNotifyOnOther = JSON.parse(localStorage.getItem('shouldNotifyOnOther'))

  const handleGetBack = () => {
    navigate(-1)
  }

  const handleSwitchBoxClick = (uniqueHash: string) => (currentState: boolean) => {
    console.log('currentState', currentState)
    localStorage.setItem(uniqueHash, JSON.stringify(currentState))
  }

  return (
    <UserSettingsWrapper>
      <BackButton onClick={handleGetBack}/>
      <UserSettingsTitle>
        Уведомления
      </UserSettingsTitle>
      <UserMenu>
        <UserMenuItem>
          Напоминания о тренировках
          <Switchbox onSelect={handleSwitchBoxClick('shouldNotifyOnTrainings')} isActive={shouldNotifyOnTrainings}/>
        </UserMenuItem>
        <UserMenuItem>
          Прочие уведомления
          <Switchbox onSelect={handleSwitchBoxClick('shouldNotifyOnOther')} isActive={shouldNotifyOnOther}/>
        </UserMenuItem>
      </UserMenu>
    </UserSettingsWrapper>
  );
};