import { RefObject, useEffect, useRef, useState } from "react";

export function useOnScreen(
  ref: RefObject<HTMLElement>,
  intersectionObserverOptions: IntersectionObserverInit = {},
  stopUpdating = false,
  condition?: boolean,
  onIntersection?: (value: boolean) => void,
) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const [isOnScreen, setIsOnScreen] = useState(false);
  const [onceShowedAtScreen, setOnceShowedAtScreen] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) => {
      if (stopUpdating && entry.isIntersecting) {
        setOnceShowedAtScreen(true);
      }

      if (condition) {
        setIsOnScreen(entry.isIntersecting);
        onIntersection?.(entry.isIntersecting);
      }
    }, intersectionObserverOptions);
  }, [intersectionObserverOptions, onIntersection, stopUpdating]);

  useEffect(() => {
    if (!observerRef.current || !ref.current) return;

    if (onceShowedAtScreen) {
      observerRef?.current?.disconnect();
      return;
    }

    observerRef.current.observe(ref.current);

    return () => {
      observerRef?.current?.disconnect();
    };
  }, [ref, onceShowedAtScreen]);

  return isOnScreen;
}
