import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import { ChangeEvent, memo, RefObject } from 'react'
import { FileUploaderInput } from './ImageUploader.styles'

export const MAX_IMG_WEIGHT = 100000000000000000000
export const MAX_DOCUMENT_WEIGHT = 1000000000

export const acceptableImgFormats = new RegExp(
  /[^\\s]+[/](gif|jpe?g|tiff?|png|webp|bmp)/
)

export const handleNonBase64ImageSelection = (
  multiple: boolean,
  filesArray: File[],
  imgSrcs: string[],
  onChange?: (value: File[], imgSrcs?: string[]) => void
) => {
  if (multiple) {
    onChange?.(filesArray, imgSrcs)
    return
  }

  const firstFile = filesArray[0]

  onChange?.([firstFile], imgSrcs)
}


export const clickOnRef = (ref: RefObject<HTMLElement>) => {
  if (ref.current) {
    ref.current?.click()
  }
}

export const useFileInputRef = () => {
  const fileLoaderRef = useRef<HTMLInputElement>(null)

  const openFileSelector = useCallback(() => {
    clickOnRef(fileLoaderRef)
  }, [])

  return [fileLoaderRef, openFileSelector] as const
}

export const fileToBase64 = (file: File): Promise<string> => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  return new Promise((resolve, reject) => {
    reader.onloadend = () => resolve(reader.result as string)
    reader.onerror = reject
  })
}

const isFileValid = (file: File, typeRE: RegExp, size: number): boolean => {
  return typeRE.test(file.type) && file.size < size
}

export const getUploadedFilesInBase64 = async (
  files: File[],
  typeRE: RegExp,
  size: number,
  skipTypeCheck?: boolean
): Promise<string[]> => {
  console.log('in getUploadedFilesInBase64')
  console.log('files length: ', files.length)
  if (files.length) {
    const convertedFiles: string[] = []

    const validateFile = async (file: File): Promise<string | null> => {
      // console.log(isFileValid(file, typeRE, size), 'file validity');
      if (skipTypeCheck || isFileValid(file, typeRE, size)) {
        const fileBase64 = await fileToBase64(file)
        return fileBase64
      }

      return null
    }

    const filePromises = files.map(validateFile)
    const results = await Promise.all(filePromises)

    for (let i = 0; i < results.length; i++) {
      const fileBase64 = results[i]
      if (fileBase64 !== null) {
        convertedFiles.push(fileBase64)
      }
    }

    if (convertedFiles.length === 0) {
      throw new Error('No valid files found')
    }

    return convertedFiles
  }

  // TODO: add error handling(toast/notification)
  throw new Error('Некорретный файл')
}

export const getImgBase64File = async (files: File[]) => {
  console.log('in getImgBase64File')
  return await getUploadedFilesInBase64(
    files,
    acceptableImgFormats,
    MAX_IMG_WEIGHT,
    true
  )
}


interface IFileLoaderProps {
  format: string
  link: RefObject<HTMLInputElement>
  onFileLoaded: (e: ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
}

export const FileLoader = memo(
  ({ format, link, onFileLoaded, multiple }: IFileLoaderProps) => {
    const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
      onFileLoaded(e)

      if (link.current?.value) {
        link.current.value = ''
      }
    }

    return (
      <FileUploaderInput
        type='file'
        accept={format}
        ref={link}
        onChange={handleInput}
        multiple={multiple}
      />
    )
  }
)

FileLoader.displayName = 'FileLoader'




type IProps = {
  multiple?: boolean
  disabled?: boolean
  restrictHeight?: number
  restrictWidth?: number
  children: (props: { image: string | undefined, onClick: () => void }) => JSX.Element
} & (
    | {
      value?: string
      notConvertToBase64?: false
      onChange?: (value: string[]) => void
    }
    | {
      value?: File | string
      notConvertToBase64?: true
      onChange?: (value: File[], imgSrcs?: string[]) => void
    }
  )

export const ImageUploader = memo(
  ({
    value,
    onChange,
    multiple,
    restrictHeight,
    restrictWidth,
    children
  }: IProps) => {
    const [image, setImage] = useState<string>('')

    // @kirill-refactor нейминг value заменить на imagePath (?)
    const [imgFileInputRef, openFileSelector] = useFileInputRef()

    const handleImgSelection = async (e: ChangeEvent<HTMLInputElement>) => {
      const files = e.target.files

      if (files) {
        const filesArray = Array.from(files)
        const imgSrcs: string[] = []
        let allowedImages: Promise<File>[] = []
        const isAnyRestrictions = restrictHeight || restrictWidth

        const results = isAnyRestrictions
          ? (await Promise.all(allowedImages)).filter(file => Boolean(file))
          : filesArray

        handleNonBase64ImageSelection(
          Boolean(multiple),
          results,
          imgSrcs,
          onChange as (value: File[], imgSrcs?: string[]) => void
        )
        return
      }
    }

    const getImg = async () => {
      console.log('value is instance of blob', value instanceof Blob, value, 'value')
      if (value instanceof File) {
        const stringImgFromFile = await getImgBase64File([value])
        console.log('stringImgFromFile', stringImgFromFile, 'stringImgFromFile[0]', stringImgFromFile[0])

        setImage(stringImgFromFile[0])
        return
      }

      setImage(value && value !== 'null' ? value : '')
    }

    useEffect(() => {
      getImg()
    }, [value])

    return (
      <>
        {
          children({ image, onClick: openFileSelector })
        }
        <FileLoader
          format={'image/*'}
          link={imgFileInputRef}
          onFileLoaded={handleImgSelection}
          multiple={multiple}
        />
      </>
    )
  }
)