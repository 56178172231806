import styled from "styled-components";
import {rem} from "../../app/styles";

interface SupportPageInputProps {
    exceeded: boolean; // Define the exceeded prop
}

export const SupportPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(30)};
  padding: 5%;
  position: relative;
`

export const SupportPageTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
`

export const SupportPageInput = styled.div<SupportPageInputProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  margin-top: 40%;
  padding: ${rem(15)};
  align-self: flex-end;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
  border: 2px solid var(--color-bg); /* Added border */
  width: 100%; /* Added width */
  position: relative;

  textarea {
    height: ${rem(250)}; /* Adjust the height accordingly */
    padding: ${rem(10)}; /* Added padding */
      margin-bottom: ${rem(20)};
    resize: none;
    font-size: ${rem(13)};
    font-weight: 400;
    line-height: ${rem(20)};
    outline: none;
      background-color: var(--color-primary);
      color: var(--color-text);
      word-break: break-word;
  }

    input::placeholder {
        color: var(--color-text); /* Set placeholder text color */
        vertical-align: top; /* Align placeholder to start at top position */
    }

  .symbol-counter {
    position: absolute;
    bottom: ${rem(10)};
    right: ${rem(10)};
    color: ${(props) => (props.exceeded ? "red" : "inherit")};
  }
`;
