import { AuthorizedPageWrapper } from "../AppRouter.styles";
import { PropsWithChildren } from "react";

export const FooterLayout = ({ children }: PropsWithChildren) => {
  return (
    <AuthorizedPageWrapper>
      {children}
    </AuthorizedPageWrapper>
  );
};
