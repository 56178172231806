import {useEffect, useMemo, useRef, useState} from "react";
import {ReactComponent as NavigateLeft} from "./navigateLeft.svg";
import {ReactComponent as NavigateRight} from "./navigateRight.svg";
import {useNavigate} from "react-router-dom";
import {getWeekdayByDate} from "../../utils/getWeekdayByDate";
import {getWeekDay} from "../../utils/getWeekDay";
import {DATEPICKER_HEIGHT_IN_PX} from "../../consts/heights";
import {useTouchSwipe} from "../../hooks/useTouchSwipe";
import {
  DatePickerBtn,
  DatePickerContainer,
  DatePickerDay,
  DatePickerDays,
  DatePickerNav,
  FilterBtn,
  SelectedMonth,
} from "./DatePicker.styles";
import {useAppSelector} from "../../hooks/useAppSelector"; // Utility function to scroll with an offset
import {ReactComponent as FilterIcon} from "./filters.svg";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setIsTransition} from "../Schedule/schedule.slice";

const scrollWithOffset = (el: HTMLElement) => {
  const yCoordinate = el.getBoundingClientRect().top + window.scrollY;
  const yOffset = -50 + -DATEPICKER_HEIGHT_IN_PX;

  window.scrollTo({
    top: yCoordinate + yOffset, behavior:
      "auto"
  });
};

export interface IDatePicker {
  dates: string[];
  className?: string;
}

export const DatePicker = ({dates, className}: IDatePicker) => {
  // Hooks
  const navigate = useNavigate();
  const selectedDate = useAppSelector((state) => state.schedule.selectedDate);
  const dispatch = useAppDispatch();
  const prevSelectedDateRef = useRef(selectedDate);

  const [weeks, setWeeks] = useState([]);
  const [currentWeekIndex, setCurrentWeekIndex] = useState(0);
  const today = new Date();

  const currentMonth = useMemo(() => {
    console.log(new Date(weeks[currentWeekIndex]?.[0]).toLocaleString("ru-Ru", {
      month: "long",
    }), 'currentMonth', weeks, currentWeekIndex)

    return new Date(weeks[currentWeekIndex]?.[0]).toLocaleString("ru-Ru", {
      month: "long",
    });
  }, [weeks, currentWeekIndex]);


  const isSameWeek = (date1: Date, date2: Date) => {
    const startOfWeek = (date: Date) => {
      const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
      return new Date(date.setDate(diff));
    };

    const endOfWeek = (date: Date) => {
      const lastday = date.getDate() - (date.getDay() - 1) + 6;
      return new Date(date.setDate(lastday));
    };

    const firstDate = new Date(date1);
    const secondDate = new Date(date2);

    return startOfWeek(firstDate) <= secondDate && secondDate <= endOfWeek(firstDate);
  };

  useEffect(() => {
    const allDates = dates ? generateAllDates(dates) : generateAllDates([today.toString()]);
    console.log(allDates)
    const dividedWeeks = divideIntoWeeks(allDates);

    setWeeks(dividedWeeks);

    const selectedIndex = allDates.indexOf(selectedDate);

    const newCurrentWeekIndex = Math.floor(selectedIndex / 7);

    setCurrentWeekIndex(newCurrentWeekIndex);
  }, [dates, selectedDate]);

  const generateAllDates = (initialDates: string[]) => {
    const allDates = [...initialDates];

    while (new Date(allDates[0]).getDay() !== 1) {
      const prevDay = new Date(allDates[0]);
      prevDay.setDate(prevDay.getDate() - 1);
      allDates.unshift(prevDay.toISOString().slice(0, 10));
    }

    while (new Date(allDates[allDates.length - 1]).getDay() !== 0) {
      const nextDay = new Date(allDates[allDates.length - 1]);
      nextDay.setDate(nextDay.getDate() + 1);
      allDates.push(nextDay.toISOString().slice(0, 10));
    }

    return allDates;
  };

  const divideIntoWeeks = (allDates: string[]) => {
    const weeks = [];
    for (let i = 0; i < allDates.length; i += 7) {
      weeks.push(allDates.slice(i, i + 7));
    }
    return weeks;
  };

  const handleGoToFilters = () => {
    navigate("/filters");
  };

  const handlePrevWeek = () => {
    setCurrentWeekIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNextWeek = () => {
    setCurrentWeekIndex((prevIndex) =>
      Math.min(weeks.length - 1, prevIndex + 1),
    );
  };

  const {handleTouchEnd, handleTouchMove, handleTouchStart} = useTouchSwipe({
    onTouchEnd: (isRightSwipe) => {
      if (!isRightSwipe) {
        handleNextWeek();
        return;
      }

      handlePrevWeek();
    },
  });

  const handleClick = (date: string) => {
    console.log('settings transition for: ', date)
    dispatch(setIsTransition(date));
  }

  return (
    <DatePickerContainer
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      className={className}
    >
      <DatePickerNav>
        <DatePickerBtn>
          <NavigateLeft onClick={handlePrevWeek}/>
        </DatePickerBtn>
        <SelectedMonth>{currentMonth}</SelectedMonth>
        <FilterBtn onClick={handleGoToFilters}>
          <FilterIcon/>
        </FilterBtn>
        <DatePickerBtn>
          <NavigateRight onClick={handleNextWeek}/>
        </DatePickerBtn>
      </DatePickerNav>
      <DatePickerDays show={true} animationKey={currentWeekIndex}>
        {weeks[currentWeekIndex]?.map((date: any) => (
          <DatePickerDay
            isDisabled={!dates.includes(date)}
            scroll={scrollWithOffset}
            to={`#schedule-block-${date}`}
            isSelected={selectedDate === date}
            key={date}
            onClick={() => handleClick(date)}
          >
            <p>{getWeekDay(getWeekdayByDate(date))}</p>
            <p>{date.slice(-2)}</p>
          </DatePickerDay>
        ))}
      </DatePickerDays>
    </DatePickerContainer>
  );
};
