import styled from "styled-components";
import {rem} from "../../app/styles";
import {Button} from "../../ui-kit/Button";

interface ModalWrapperProps {
    showModal: boolean; // Define the prop type
}
export const ModalWrapper = styled.div<ModalWrapperProps>`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--color-bg); /* Set background color */
    padding: ${rem(30)};
    border-radius: ${rem(22)};
    max-width: ${rem(400)};
    width: 85%;
    max-height: calc(100% - ${rem(80)});
    overflow-y: auto;
    z-index: 1000;
    transition: opacity 0.3s ease-in-out; /* Add transition for opacity */
    opacity: ${({ showModal }) => (showModal ? '1' : '0')}; /* Set opacity based on showModal */
    visibility: ${({ showModal }) => (showModal ? 'visible' : 'hidden')}; /* Toggle visibility */
`;

export const ModalTitle = styled.h2`
    color: var(--color-text);
    font-size: ${rem(20)};
    font-weight: 700;
    line-height: ${rem(25)};
`;

export const ModalContent = styled.p`
    font-size: ${rem(15)};
    margin-bottom: ${rem(20)};
    color: var(--color-text);
    margin-top: ${rem(20)};
`;

export const ModalButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: ${rem(40)};
`;

export const ModalButton = styled.button`
    padding: ${rem(10)} ${rem(20)};
    border: none;
    border-radius: ${rem(22)};
    cursor: pointer;
    font-size: ${rem(12)};
    font-weight: bold;
    &:hover {
        opacity: 0.8;
    }
`;

export const YesButton = styled(Button)`
    background-color: var(--color-action);
    color: var(--color-primary);
    width: ${rem(130)};
    font-size: ${rem(16)};
`;

export const CancelButton = styled(Button)`
    background-color: var(--color-text);
    color: var(--color-bg);
    width: ${rem(130)};
    font-size: ${rem(16)};
`;

export const ModalBackground = styled.div<ModalWrapperProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Set faded background color */
    z-index: 999;
    transition: opacity 0.3s ease-in-out; /* Add transition for opacity */
    opacity: ${({ showModal }) => (showModal ? '1' : '0')}; /* Set opacity based on showModal */
    visibility: ${({ showModal }) => (showModal ? 'visible' : 'hidden')}; /* Toggle visibility */
`;