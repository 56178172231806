import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as BellIcon } from "./bell.svg";
import {rem} from "../../app/styles";
import BellModal from "./BellModal";

interface ModalProps {
    trainingName: string;
    buttonStartTime: string;
    buttonStartDate: string;
    AppointmentID: string;
    type: number;
}

const Bell = styled.button`
  position: fixed;
  bottom: ${rem(10)};
  right: 10px;
  background-color: var(--color-bg);
  border: 3px solid var(--color-primary);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
    display: flex; /* Add flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
`;

const AnimatedBellIcon = styled(BellIcon)`
    animation: ring 0.12s 10;
    @keyframes ring {
        0%, 100% {
            transform: rotate(0deg);
        }
        50% {
            transform: rotate(7deg);
        }
    }
    animation-delay: 2s;
`;

const BellButton = ({ trainingName, buttonStartDate, buttonStartTime, type, AppointmentID }: ModalProps) => {
    const [showModal, setShowModal] = useState(false);

    // Toggle modal visibility on bell button click
    const toggleModal = () => {
        setShowModal(!showModal);
        console.log("Modal toggled");
    };

    // Close modal when clicking outside of it


    return (
        <>
            <Bell onClick={toggleModal}>
            <AnimatedBellIcon />
            </Bell>
            {showModal && <BellModal showModal={showModal} type={type} buttonStartDate={buttonStartDate} buttonStartTime={buttonStartTime} AppointmentID={AppointmentID} trainingName={trainingName} closeModal={() => setShowModal(false)} />}
        </>
);
};

export default BellButton;
