import {Key, PropsWithChildren} from "react";
import {Transition} from "@headlessui/react";

interface IProps {
  show?: boolean;
  animationKey?: Key;
  className?: string;
  fullWidth?: boolean;
  beforeLeave?: () => void;
  afterEnter?: () => void;
}

export const FadeAnimation = ({
                                children,
                                animationKey,
                                className,
                                show,
                                fullWidth,
                                beforeLeave,
                                afterEnter
                              }: PropsWithChildren<IProps>) => {
  return (
    <Transition
      style={fullWidth ? {width: "100%", height: "100%"} : {}}
      className={className}
      key={animationKey}
      as={"div"}
      enter={"transition-opacity-out"}
      enterFrom={"opacity-0"}
      enterTo={"opacity-100 overlap"}
      leave={"transition-opacity-in"}
      leaveFrom={"opacity-100"}
      leaveTo={"opacity-0"}
      show={show}
      appear
      beforeLeave={beforeLeave}
      afterEnter={afterEnter}
    >
      {children}
    </Transition>
  );
};
