import styled from "styled-components";
import {rem} from "../../app/styles";

export const FreezePageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(30)};
  padding: 12% 5%;
  position: relative;
`

export const FreezePageTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
`

export const FreezePageDesc = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  margin-top: 20%;
  padding: ${rem(15)};
  background: var(--color-bg);
  align-self: flex-end;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};

  p {
    font-size: ${rem(17)};
    font-weight: 700;
  }
`
