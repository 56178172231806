import { useState } from "react";

const minSwipeDistance = 50;

interface IUseTouchSwiper {
  onTouchStart?: () => void;
  onTouchMove?: () => void;
  onTouchEnd?: (isRightSwipe: boolean) => void;
}

export const useTouchSwipe = ({
  onTouchStart,
  onTouchMove,
  onTouchEnd,
}: IUseTouchSwiper) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const handleTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
    onTouchStart?.();
  };

  const handleTouchMove = (e: any) => {
    setTouchEnd(e.targetTouches[0].clientX);
    onTouchMove?.();
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;

    if (isLeftSwipe || isRightSwipe) {
      onTouchEnd?.(isRightSwipe);
    }
  };

  return {
    handleTouchEnd,
    handleTouchStart,
    handleTouchMove,
  };
};
