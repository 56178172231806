import { useCallback, useRef, useState } from "react";

export const useScrollElementIntoView = <T extends HTMLElement>() => {
  const elementRef = useRef<T>(null);
  const [isScrollingElementIntoView, setIsScrollingElementIntoView] =
    useState(false);

  const scrollElementIntoView = useCallback(
    (scrollIntoViewOptions?: ScrollIntoViewOptions) => {
      const elementRefEl = elementRef.current;

      if (!elementRefEl) return;

      setIsScrollingElementIntoView(true);

      setTimeout(() => {
        elementRefEl.scrollIntoView(scrollIntoViewOptions);
      }, 0);
    },
    [elementRef],
  );

  return [elementRef, scrollElementIntoView] as const;
};
