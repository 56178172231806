import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {useNavigate} from "react-router-dom";
import {FreezePageDesc, FreezePageTitle, FreezePageWrapper} from './FreezePage.styles'
import {FreezeDatesTypography, FreezeDatesWrapper, StyledDatePicker} from "../ProfilePage/ProfilePage.styles";
import axios from "axios";
import {ChangeEvent, useState} from "react";
import {useSendToAuth} from "../../hooks/useSendToAuth";
import {useAppSelector} from "../../hooks/useAppSelector";
// @ts-ignore
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import ym from "react-yandex-metrika";

registerLocale("ru", ru);

// @ts-ignore
const tg = window.Telegram.WebApp;

export const FreezePage = () => {
  const profile = useAppSelector(state => state.user.profile)
  const navigate = useNavigate();
  const today = new Date();
  const [isLoading, setIsLoading] = useState(false)
  const addDays = (days: number, date: Date) => {
    const newDate = new Date(date)
    newDate.setDate(newDate.getDate() + days);
    return newDate
  };
  const limit = addDays(5, today)
  const [freezeFrom, setFreezeFrom] = useState(today);
  const [freezeUntil, setFreezeUntil] = useState(limit);
  const sendToAuth = useSendToAuth();

  const handleGetBack = () => {
    navigate(-1)
  }

  async function handleFreeze() {
    setIsLoading(true)
    const clubId = localStorage.getItem('club')
    const res = await axios.post('https://api.profitnes.club/api/frost-request', {
      surname: profile.LastName,
      name: profile.Name,
      fathername: profile.SecondName,
      freeze_from: freezeFrom,
      freeze_to: freezeUntil
    })
      .then(() => {
        tg.showAlert('Заявка на заморозку абонемента успешно отправлена')
        ym('reachGoal',`freeze_200_${clubId}`)
        setIsLoading(false)
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          sendToAuth()
        }
        if (err?.response?.status === 400) {
          tg.showAlert('Вы уже направили заявку на заморозку. Сотрудник отдела продаж в ближайшее время свяжется с вами!')
        }
        setIsLoading(false)
        ym('reachGoal',`freeze_error_${clubId}`)
      })
  }

  const handleFocus = (e: ChangeEvent<HTMLInputElement>) => {
    e.target.blur();
  };

  return (
    <FreezePageWrapper>
      <BackButton onClick={handleGetBack}/>
      <FreezePageTitle>
        Заявка на заморозку
      </FreezePageTitle>

      <FreezeDatesTypography>
        Выберите период заморозки
      </FreezeDatesTypography>

      <FreezeDatesWrapper>
        <FreezeDatesTypography>
          С
        </FreezeDatesTypography>
        <StyledDatePicker
          selected={freezeFrom}
          minDate={today}
          onChange={(date: Date) => setFreezeFrom(date)}
          locale="ru"
          dateFormat="dd/MM/yyyy"
          readonly
          onFocus={handleFocus}
        />
        <FreezeDatesTypography>
          по
        </FreezeDatesTypography>
        <StyledDatePicker
          selected={freezeUntil}
          minDate={addDays(5, freezeFrom)}
          onChange={(date: Date) => setFreezeUntil(date)}
          locale="ru"
          dateFormat="dd/MM/yyyy"
          readonly
          onFocus={handleFocus}
        />
      </FreezeDatesWrapper>

      <FreezePageDesc>
        <>
          <p>Внимание</p>
          В данной форме вы подаете заявку на заморозку. После оставления вами заявки, с вами свяжется сотрудник отдела продаж. Заморозка клубной карты осуществляется с минимальным сроком 5 календарных дней. Максимальный срок заморозки
          зависит от типа вашей клубной карты. Для получения более детальной информации вы можете обратиться в отдел
          продаж клуба.
        </>
      </FreezePageDesc>

      <MainButton text={"Отправить заявку"}
                  progress={isLoading}
                  disabled={isLoading}
                  onClick={handleFreeze}
                  color={"#F44"}
                  textColor={"#000"}
      />
    </FreezePageWrapper>
  );
};