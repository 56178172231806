import {
  FiltersPageHeader,
  FiltersPageLayout,
  FiltersPageTitle,
} from "./FiltersPage.styles";
import { ReactComponent as BackIcon } from "../TrainingPageContent/back.svg";
import { BackBtn } from "../TrainingPageContent/TrainingPageContent.styles";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ShevronRight } from "../ProfilePage/ShevronRight.svg";
import { UserMenu, UserMenuItem } from "../ProfilePage/ProfilePage.styles";
import { Button } from "../../ui-kit/Button";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import {
  setTimeFromFilter,
  setTrainerFilter,
  setTrainingFilter,
} from "../Schedule/schedule.slice";


//@ts-ignore
const tg = window.Telegram.WebApp
export const FiltersPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetBack = () => {
    navigate(-1);
  };

  const handleOpenTrainingFilter = () => {
    navigate("/filters/training");
  };

  const handleOpenTrainerFilter = () => {
    navigate("/filters/trainer");
  };

  const handleOpenTimeFromFilter = () => {
    navigate("/filters/timefrom");
  };

  const handleOpenClubFilter = () => {
    navigate('/filters/club')
  }

  const handleResetFilters = () => {
    dispatch(setTrainerFilter([""]));
    dispatch(setTrainingFilter([""]));
    dispatch(setTimeFromFilter([""]));
    tg.showAlert("Фильтры сброшены")
  };

  return (
    <FiltersPageLayout>
      <BackButton onClick={handleGetBack}/>
      <FiltersPageHeader>
        <FiltersPageTitle>ФИЛЬТРАЦИЯ</FiltersPageTitle>
      </FiltersPageHeader>
      <UserMenu>
        <UserMenuItem onClick={handleOpenTrainingFilter}>
          Вид занятий
          <ShevronRight />
        </UserMenuItem>
        <UserMenuItem onClick={handleOpenTrainerFilter}>
          Тренер
          <ShevronRight />
        </UserMenuItem>
        <UserMenuItem onClick={handleOpenTimeFromFilter}>
          Начало занятия
          <ShevronRight />
        </UserMenuItem>
        <UserMenuItem onClick={handleOpenClubFilter}>
          Клуб
          <ShevronRight />
        </UserMenuItem>
      </UserMenu>
      <MainButton
          text={"Сбросить"}
          color={"#E7E7E7"}
          textColor={"#000000"}
          onClick={handleResetFilters} />
    </FiltersPageLayout>
  );
};
