import { PropsWithChildren } from "react";
import {
  FiltersLayoutButton,
  FiltersLayoutButtonWrapper,
  FiltersLayoutWrapper,
} from "./FiltersLayout.styles";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
interface IProps {
  onClick?: () => void;
}

export const FiltersLayout = ({
  children,
  onClick,
}: PropsWithChildren<IProps>) => {
  return (
    <FiltersLayoutWrapper>
      {children}
      <MainButton
          text={"Применить"}
          color={"#F5C71A"}
          textColor={"#000000"}
          onClick={onClick}
      />
      {/*<FiltersLayoutButtonWrapper>*/}
      {/*  <FiltersLayoutButton variant={"filled"} onClick={onClick}>*/}
      {/*    Применить*/}
      {/*  </FiltersLayoutButton>*/}
      {/*</FiltersLayoutButtonWrapper>*/}
    </FiltersLayoutWrapper>
  );
};
