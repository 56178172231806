import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {
    LogoutAction,
    ProfilePageHeader,
    ProfilePageWrapper,
    UserCreds, UserInfo, UserInfoTitle, UserMenu, UserMenuItem, UserName,
    UserPic, UserPicImage, UserPicPlaceholder,
    UserPicWrapper
} from "../ProfilePage/ProfilePage.styles";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {ImageUploader} from "../../ui-kit/ImageUploader";
import Avatar from "../ProfilePage/user.png";
import React from "react";
import {Button} from "../../ui-kit/Button";
import {phoneHandler} from "../../utils/phoneHandler";
import {ErrorPageMessageWrapper, ErrorPageTypography, ErrorPageWrapper, IconWrapper, ButtonWrapper} from "./ErrorPage.styles";
import {ReactComponent as RepairIcon} from "./repair.svg";
import axios from "axios";
import qs from "qs";
import {useNavigate} from "react-router-dom";

export const ErrorPage = () => {
    const navigate = useNavigate();

    const handleErrorFixed = async () => {
        try {
            const res = await axios.get('https://api.profitnes.club/api/get-schedule-test', {
                paramsSerializer: (param) => {
                    return qs.stringify(param, { arrayFormat: "comma" });
                },
            });
            navigate('/schedule');
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <ErrorPageWrapper>
            <BackButton onClick={() => navigate('/')}
            ></BackButton>
            <ErrorPageMessageWrapper>
                <IconWrapper>
                    <RepairIcon style={{ width: '64px', height: '64px', color: 'white' }} />
                </IconWrapper>
                <ErrorPageTypography>
                    <b>Уважаемые клиенты!</b>
                </ErrorPageTypography>
                <ErrorPageTypography>
                    Произошла серверная ошибка, но мы уже работаем над её устранением. Пока проблема решается, вы можете обратиться в отдел продаж для записи на необходимое занятие.
                </ErrorPageTypography>
                <ErrorPageTypography>
                    Спасибо за ваше понимание и терпение!
                </ErrorPageTypography>
                {/*<ButtonWrapper>*/}
                {/*    <Button variant={'filled'} onClick={async () => handleErrorFixed()}>*/}
                {/*        Обновить*/}
                {/*    </Button>*/}
                {/*</ButtonWrapper>*/}
                <MainButton
                    text={"Обновить"}
                    color={"#F5C71A"}
                    textColor={"#000000"}
                    onClick={async () => handleErrorFixed()}
                />
            </ErrorPageMessageWrapper>
        </ErrorPageWrapper>
    );
}