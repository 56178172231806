import { useEffect, useState } from "react";
import {
    FiltersPageLayout,
    FiltersTitle,
    FilterVariant, FilterVariantWrapper,
    TrainingFilterWrapper,
    VariantsWrapper,
} from "./FiltersPage.styles";
import axios from "axios";
import {ReactComponent as CheckboxIcon} from './checkbox.svg'
import {ReactComponent as UncheckboxIcon} from "./Uncheckbox.svg";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";
import {useNavigate} from "react-router-dom";
import {useSendToAuth} from "../../hooks/useSendToAuth";
import { FiltersLayout } from "../../app/layouts";
import {setClubFilter} from "../Schedule/schedule.slice";
import {ERoutes} from "../../app/AppRouter.types";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";

export interface IClub {
    id: number
    title: string
}

export const ClubFilter = () => {
    const selectedClub = localStorage.getItem('USER_CLUB')
    const selectedClubId = localStorage.getItem('USER_CLUB_ID')

    const initialClub: IClub = {
        id: Number(selectedClubId),
        title: selectedClub
    }

    const [variants, setVariants] = useState<IClub[]>([])
    const [selectedOption, setSelectedOption] = useState<IClub>(initialClub)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sendToAuth = useSendToAuth()

    const handleGetBack = () => {
        navigate(-1);
    };

    const handleGetVariants = () => {
        const res = axios.post('https://api.profitnes.club/api/get-clubs').then((res) => {
            setVariants(res?.data)
        }).catch((err) => {
            if(err?.response?.status === 401) {
                sendToAuth()
            }

            const stringErrorStatus = err?.response?.status.toString()

            if(stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
                navigate(ERoutes.MAIN)
            }
        })
    }

    useEffect(() => {
        handleGetVariants();
    }, []);

    const handleSelectOption = (option: IClub) => {
        setSelectedOption(option)
    };

    const handleSubmitFilters = async () => {
        const res = await axios.post('https://api.profitnes.club/api/switch-club', {club_id: selectedOption.id})
            .then((res) => {
                localStorage.setItem('token', res.data.data.token)
                axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
                dispatch(setClubFilter(selectedOption))
                localStorage.setItem('USER_CLUB', selectedOption.title)
                localStorage.setItem('USER_CLUB_ID', String(selectedOption.id))
                navigate("/");
            })
    };

    return (
        <>
            <FadeAnimation show={Boolean(variants.length == 0)}>
                <AbsolutePreloader/>
            </FadeAnimation>
        <FadeAnimation show={Boolean(variants.length > 0)}>
        <FiltersLayout onClick={handleSubmitFilters}>
            <FiltersPageLayout>
                <BackButton onClick={handleGetBack}/>
                <TrainingFilterWrapper>
                    <FiltersTitle>Клуб</FiltersTitle>
                    <VariantsWrapper>
                        {variants
                            .map((variant, index) => (
                                <FilterVariantWrapper
                                    onClick={(e) => handleSelectOption(variant)}
                                    key={index}>
                                    <FilterVariant>
                                        {variant.title}
                                    </FilterVariant>
                                    {selectedOption?.title === variant.title ? <CheckboxIcon /> : <UncheckboxIcon />}
                                </FilterVariantWrapper>
                            ))}
                    </VariantsWrapper>
                </TrainingFilterWrapper>
            </FiltersPageLayout>
        </FiltersLayout>
        </FadeAnimation>
            </>
    );
};