import styled from "styled-components";
import {rem} from "../../app/styles";

export const PaymentSuccessfulPageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(20)};
    padding: 5%;
    position: relative;
    min-height: 100vh;  /* Задает минимальную высоту в 100% высоты экрана */
    justify-content: center; /* Центрирование содержимого по вертикали */

`

export const BodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;  /* Центрирование по вертикали */
    height: 100%;  /* Обеспечивает контейнеру максимальную высоту */
`