import styled from "styled-components";
import { rem } from "../../app/styles";

interface IProps {
  isCancelled: boolean
}

export const TrainingCardWrapper = styled.div<IProps>`
  padding: ${rem(20)};
  width: 100%;
  border-radius: ${rem(15)};
  background: var(--color-bg);
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`

export const TrainingCardTitle = styled.div`
  color: var(--color-text);
  font-size: ${rem(17)};
  font-weight: 700;
  line-height: normal;
`

export const TrainingCardSignedMark = styled.div<{ isSigned: boolean, isConducted: boolean, isCommercial: boolean }>`
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(6)};
  display: flex;
  align-items: center;
  justify-content: center;
  

${({ isSigned }) => isSigned && `
    background: var(--color-action);
  `}

${({ isConducted, isCommercial }) => (isConducted && isCommercial) && `
    display: none
  `}

${({ isConducted, isCommercial }) => (isConducted && !isCommercial) && `
    background: var(--color-text);
  `}
`

export const TrainingCardCommercialMark = styled.div<{ isCommercial: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(6)};
  align-self: end;
  

${({ isCommercial }) => isCommercial && `
    background: #A9C600;
  `}
`

export const RubleWrapper = styled.div<{ isSigned: boolean; isConducted: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(20)};
  height: ${rem(20)};
  border-radius: ${rem(6)};
  align-self: end;

  ${({ isSigned }) => isSigned && `
    border: 1px solid var(--color-action);
  `}

  ${({ isConducted }) => isConducted && `
    border: 1px solid var(--color-text);
  `}
  
`

export const TrainingCardPrimaryField = styled.p`
  color: var(--color-action);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
`

export const TrainingCardPrimaryTime = styled(TrainingCardPrimaryField)`
  align-self: flex-start;
`

export const TrainingCardTrainer = styled.p`
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
`

export const TrainingsCardFields = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    margin-top: ${rem(50)};
  }
`

export const TrainingCardSquareWrapper = styled.div`
  padding: ${rem(5)}
`

export const TrainingCardSquare = styled.div`
  height: ${rem(24)};
  width: ${rem(24)};
  background: var(--color-action);
  border-radius: ${rem(8)};
`