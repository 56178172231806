import styled from "styled-components";
import { rem } from "../../app/styles";

export const FiltersPageLayout = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 10%;

  Button {
    width: 80%;
    align-self: center;
    margin-top: ${rem(250)};
    margin-bottom: ${rem(30)};
  }
`;

export const FiltersPageTitle = styled.h3`
  color: var(--color-text);
  text-align: center;
  font-size: 20px;
  font-weight: 800;
  line-height: 25px;
`;

export const FiltersPageHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: ${rem(45)};
  width: 100%;
`;

export const TrainingFilterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  // gap: ${rem(10)};
`;

export const FilterVariantWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-third);
`;

export const FilterVariant = styled.div`
    color: var(--color-text);
    width: 100%;
    height: ${rem(70)};
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding-left: ${rem(5)};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
    margin: ${rem(2)};
    margin-right: ${rem(10)};
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1 1 auto;
`;

export const FiltersTitle = styled.h3`
    display: flex;
    color: var(--color-text);
    margin-bottom: ${rem(30)};
    text-align: center;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    align-self: center;
`;

export const FilterInputWrapper = styled.h3`
    display: flex;
    padding-left: ${rem(15)};
    align-content: space-between;
    align-items: center;
    background-color: #2C2C2E;
    border-radius: 10px;
`;

export const FilterOptionWrapper = styled.div`
    
    `;