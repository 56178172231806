import {Button} from "../../ui-kit/Button";
import {
  LogoutAction,
  ProfilePageHeader,
  ProfilePageWrapper,
  UserCreds,
  UserInfo,
  UserInfoField,
  UserInfoTitle,
  UserMenu,
  UserMenuItem,
  UserName,
  UserPic, UserPicImage,
  UserPicPlaceholder,
  UserPicWrapper,
} from "./ProfilePage.styles";
import Avatar from "./user.png";
import {useNavigate} from "react-router-dom";
import {ReactComponent as ShevronRight} from "./ShevronRight.svg";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import axios from "axios";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {logout, setUserProfile} from "../../app/store/userSlice";
import React, {useCallback, useEffect, useState} from "react";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";
import {useSendToAuth} from "../../hooks/useSendToAuth";
// @ts-ignore
import DatePicker, {registerLocale} from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import {ERoutes} from "../../app/AppRouter.types";
import {ImageUploader} from "../../ui-kit/ImageUploader";
import {BackButton, MainButton} from "@vkruglikov/react-telegram-web-app";

registerLocale("ru", ru);

// @ts-ignore
const tg = window.Telegram.WebApp;

function appendValueToFormData(
  formData: FormData,
  key: string,
  value: unknown
) {
  if (typeof value === 'object' || (Array.isArray(value) && value.length > 0)) {
    const json = JSON.stringify(value)
    formData.append(key, json)
    return
  }

  formData.append(key, String(value))
}

export function generateFormDataForProductSubmit(data: any, withImage?: boolean): FormData {
  const formData = new FormData()
  const {photos, ...restData} = data

  for (const [key, value] of Object.entries(restData)) {
    if (value !== undefined && value !== null) {
      appendValueToFormData(formData, key, value)
    }
  }

  if (withImage && photos) {
    if (Array.isArray(photos)) {
      for (const photo of photos) {
        formData.append('photos[]', photo)
      }
    } else {
      formData.append('photos[]', photos)
    }
  }

  return formData
}


export interface IProfile {
  Status: string;
  ClientID: string;
  Name: string;
  SecondName: string;
  LastName: string;
  Birthday: string;
  Sex: number;
  Email: string;
  Card: {
    EndDate: string;
    Status: string;
  };
  EditAllowed: boolean;
  AppointmentsToRate: string;
  Avatar: string
}

export interface IStatusDesc {
  status: string
  desc: string
}

export const UserStatusesData: IStatusDesc[] = [
  {
    status: 'Active',
    desc: 'активен'
  },
  {
    status: 'NotActive',
    desc: 'не активен'
  },
  {
    status: 'Frozen',
    desc: 'заморожен'
  },
  {
    status: 'Locked',
    desc: 'заблокирован'
  },
  {
    status: 'Closed',
    desc: 'закрыт'
  }
]

export const ProfilePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [profile, setProfile] = useState<IProfile>();
  const sendToAuth = useSendToAuth();
  const image = profile?.Avatar ? [profile.Avatar] : []
  const [isLoading, setIsLoading] = useState(false)

  const handleGoToSettings = () => {
    navigate("/settings");
  };

  const handleGetBack = () => {
    navigate("/schedule");
  };

  async function handleLogOut() {
    const res = await axios.post("https://api.profitnes.club/api/logout");
    dispatch(logout);
    localStorage.clear();
    axios.defaults.headers.common["Authorization"] = ``;
    navigate("/");
    window.location.reload();
  }

  async function getProfile() {
    const res = await axios.get('https://api.profitnes.club/api/view-profile')
      .then((res) => {
        setProfile(res?.data)
        dispatch(setUserProfile(res?.data))
        if (localStorage.getItem('club') == null) {
          localStorage.setItem('club', res.data.data.club_id)
        }
        console.log(localStorage.getItem('club'))
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          sendToAuth()
        }

        const stringErrorStatus = err?.response?.status.toString()

        if (stringErrorStatus.startsWith('5') || stringErrorStatus.startsWith('4')) {
          navigate(ERoutes.MAIN)
        }
      })
  }

  useEffect(() => {
    getProfile();
  }, []);

  const isProfileFrozen = profile?.Card.Status === 'Frozen'
  // const showProfileDate = profile?.Card.Status === 'Active' || profile?.Card.Status === 'NotActive'

  async function handleUnfreeze() {
    setIsLoading(true)
    const res = await axios.post('https://api.profitnes.club/api/defrost-request', {
      surname: profile.LastName,
      name: profile.Name,
      fathername: profile.SecondName
    })
      .then(() => {
        setIsLoading(false)
        tg.showAlert('Заявка на разморозку абонемента успешно отправлена')
      })
      .catch((err) => {
        setIsLoading(false)
        if (err?.response?.status === 401) {
          sendToAuth()
        }
      })
  }

  const defineUserStatus = () => {
    let desc = 'произошла ошибка'
    UserStatusesData.map((status) => {
      if (status.status === profile.Card.Status) {
        desc = `Абонемент ${status.desc}`
      }
    })
    return desc
  }

  const handleGoToFreeze = () => {
    navigate('/freeze')
  }

  const handleGoToSupport = () => {
    navigate('/support')
  }

  const handleGoToShopping = () => {
    navigate('/shopping')
  }

  const handleProfilePictureChange = useCallback(
    async (userPic: File[]) => {

      const requestConfig = generateFormDataForProductSubmit({photos: userPic[0]}, true)

      try {
        await axios.post('https://api.profitnes.club/api/upload-avatar', requestConfig)
        await getProfile()
        tg.showAlert('Новая аватарка успешно загружена')
      } catch (err) {
        if (err?.response?.status === 401) {
          sendToAuth()
        }
      }
    },
    []
  )

  return (
    <>
      <FadeAnimation show={!profile}>
        <AbsolutePreloader/>
      </FadeAnimation>
      <FadeAnimation show={!!profile}>
        {profile && (
          <ProfilePageWrapper>
            <BackButton onClick={handleGetBack}/>
            <ProfilePageHeader>
              <UserCreds>
                <ImageUploader value={image?.[0]} onChange={handleProfilePictureChange}>
                  {({image: uploaderImage, onClick}) => (
                    <UserPicWrapper onClick={onClick}>
                      {uploaderImage ? (
                        <UserPic>
                          <UserPicImage src={uploaderImage}/>
                        </UserPic>
                      ) : (
                        <UserPicPlaceholder>
                          <UserPicImage src={Avatar}/>
                        </UserPicPlaceholder>
                      )}
                    </UserPicWrapper>
                  )}
                </ImageUploader>
              </UserCreds>
              <UserInfo>
                <UserInfoTitle status={profile.Card.Status}>
                  {defineUserStatus()}
                </UserInfoTitle>
                {/*{showProfileDate &&*/}
                {/*  <UserInfoField>*/}
                {/*    до {profile.Card.EndDate}*/}
                {/*  </UserInfoField>}*/}
              </UserInfo>
            </ProfilePageHeader>
            <UserName>
              {profile.Name} {profile.LastName}
            </UserName>
            <UserMenu>
              <UserMenuItem onClick={handleGoToSettings}>
                Настройки
                <ShevronRight/>
              </UserMenuItem>
              {profile.Card.Status === 'Active' &&
                <UserMenuItem onClick={handleGoToFreeze}>
                  Заявка на заморозку
                  <ShevronRight/>
                </UserMenuItem>
              }
              <UserMenuItem onClick={handleGoToSupport}>
                Написать в поддержку
                <ShevronRight/>
              </UserMenuItem>
              {/* Conditionally render "Магазин услуг" if club is not 540 */}
              {localStorage.getItem('club') !== '540' && (
                  <UserMenuItem onClick={handleGoToShopping}>
                    Магазин услуг
                    <ShevronRight />
                  </UserMenuItem>
              )}
              <LogoutAction onClick={handleLogOut}>Выйти</LogoutAction>
            </UserMenu>
            {isProfileFrozen &&
                <MainButton text="Разморозить"
                            progress={isLoading}
                            disabled={isLoading}
                            onClick={handleUnfreeze}
                            color={"#F5C71A"}
                            textColor={"#000000"}
                />
              // <Button variant={"filled"} onClick={handleUnfreeze}>Разморозить</Button>
            }
          </ProfilePageWrapper>
        )}
      </FadeAnimation>
    </>
  );
};
