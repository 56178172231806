import { OtpInputWrapper, OtpGroup } from './OtpInput.styles'
import {RE_DIGIT} from "../../utils/regexp";
import React, {useMemo} from "react";

export interface IOtpProps {
    value: string
    valueLength: number
    onChange: (value: string) => void
}

export const OtpInput = ( {value, valueLength, onChange}: IOtpProps ) => {
    const valueItems = useMemo(() => {
        const valueArray = value.split('')
        const items: string[] = []

        for (let i = 0; i < valueLength; i++) {
            const char = valueArray[i]

            if(RE_DIGIT.test(char)) {
                items.push(char);
            } else {
                items.push('')
            }
        }

        return items
    }, [value, valueLength])

    const inputOnChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        idx: number
    ) => {
        const target = e.target;
        let targetValue = target.value.trim();
        const isTargetValueDigit = RE_DIGIT.test(targetValue);

        if (!isTargetValueDigit && targetValue !== '') {
            return;
        }

        targetValue = isTargetValueDigit ? targetValue : ' ';

        const targetValueLength = targetValue.length;

        if (targetValueLength === 1) {
            const newValue =
                value.substring(0, idx) + targetValue + value.substring(idx + 1);

            onChange(newValue);

            if (!isTargetValueDigit) {
                return;
            }

            const nextElementSibling =
                target.nextElementSibling as HTMLInputElement | null;

            if (nextElementSibling) {
                nextElementSibling.focus();
            }
        } else if (targetValueLength === valueLength) {
            onChange(targetValue);

            target.blur();
        }
    };

    const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        const targetValue = target.value;

        target.setSelectionRange(0, targetValue.length);

        if (e.key !== 'Backspace' || target.value !== '') {
            return;
        }

        const previousElementSibling =
            target.previousElementSibling as HTMLInputElement | null;

        if (previousElementSibling) {
            previousElementSibling.focus();
        }
    };

    const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        const { target } = e;

        target.setSelectionRange(0, target.value.length);
    };

    return (
        <OtpInputWrapper>
            <OtpGroup>
                {valueItems.map((digit, idx) => (
                    <input
                        key={idx}
                        placeholder={'⚪'}
                        type={'text'}
                        inputMode={'numeric'}
                        autoComplete={'one-time-code'}
                        pattern={'\d{1}'}
                        maxLength={valueLength}
                        value={digit.toString()}
                        onChange={(e) => inputOnChange(e, idx)}
                        onKeyDown={inputOnKeyDown}
                        onFocus={inputOnFocus}
                    />
                ))}
            </OtpGroup>
        </OtpInputWrapper>
    );
};