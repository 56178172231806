import React, { useEffect, useState } from 'react';
import serviceImage from "../ShoppingPage/5dbdc0835911016e2d380125.jpg";
import { ShoppingCartPageTitle, ShoppingCartPageWrapper, ProceedPaymentButton } from "./ShoppingCartPage.styles";
import { FreezePageDesc } from "../FreezePage/FreezePage.styles";
import {Base64} from "js-base64";

//@ts-ignore
const tg = window.Telegram.WebApp;

export const ShoppingCartPage = () => {
    const [orderId, setOrderId] = useState<string | null>(null);
    const [clientId, setClientId] = useState<string | null>(null);
    const [price, setPrice] = useState<string | null>(null);
    const [serviceName, setServiceName] = useState<string | null>(null);
    const [paykeeperURL, setPaykeeperURL] = useState<string | null>(null);

    useEffect(() => {
        // Extract the token from the URL
        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');
        const name = decodeURI(params.get('name'))

        if (token && name) {
            const decodedString = Base64.decode(token);

            // Split the decoded string to extract the values
            const [orderId, clientDid, price, clubId] = decodedString.split('|');

            if (clubId == '539') {
                setPaykeeperURL('https://2profitnesbot-t.server.paykeeper.ru/create')
            } else if (clubId == '538') {
                setPaykeeperURL('https://profitnesbot-t.server.paykeeper.ru/create')
            }

            // Set the state variables
            setOrderId(orderId);
            setClientId(clientDid);
            setPrice(price);
            setServiceName(name);
        }
    }, []);

    return (
        <ShoppingCartPageWrapper>
            <ShoppingCartPageTitle>Оплата</ShoppingCartPageTitle>
            <img
                src={serviceImage}
                alt="Service"
                style={{
                    display: 'block',
                    margin: '10px auto',
                    borderRadius: '14px',
                    width: '100%',
                    height: '150px',
                    objectFit: 'cover',
                    objectPosition: 'center',
                }}
            />
            <h3 style={{ textAlign: 'left', color: 'white', marginBottom: '10px' }}>{serviceName}</h3>
            <div
                style={{
                    border: '1px solid #F5C71A',
                    borderRadius: '14px',
                    padding: '10px',
                    marginBottom: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    color: '#F5C71A',
                }}
            >
                <span>Стоимость</span>
                <span>{parseInt(price, 10).toLocaleString('ru-RU')}</span>
            </div>
            <FreezePageDesc>
                <>
                    <p>Внимание</p>
                    Вы будете перенаправлены на страницу платежного сервиса для проведения процесса оплаты через зашифрованный шлюз. При успешной оплате вам будет предложено вернуться в магазин. С вами свяжется сотрудник отдела продаж для оформления услуги
                </>
            </FreezePageDesc>
            <form method="POST" action={paykeeperURL} acceptCharset="utf-8">
                <input type="hidden" name="orderid" value={orderId ?? ''} />
                <input type="hidden" name="clientid" value={clientId ?? ''} />
                <input type="hidden" name="sum" value={price ?? ''} />
                <input type="hidden" name="service_name" value={serviceName ?? ''} />
                <ProceedPaymentButton variant={'primary'}>
                    Перейти к оплате
                </ProceedPaymentButton>
            </form>
        </ShoppingCartPageWrapper>
    );
};