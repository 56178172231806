import './styles/null.scss';
import './styles/App.scss';
import './styles/vars.scss';
import './fonts/Fontspring-DEMO-integralcf-bold.otf';
import {AppRouter} from "./app/AppRouter";
import {Provider} from "react-redux";
import store from "./app/store";
import axios from "axios";
import {SkeletonTheme} from "react-loading-skeleton";
import {WebAppProvider} from "@vkruglikov/react-telegram-web-app";
import { YMInitializer } from 'react-yandex-metrika';


// @ts-ignore
const tg = window.Telegram.WebApp

function App() {
  tg.expand()
  // tg.onEvent('viewportChanged', () => tg.expand())
  // tg.viewportHeight = 3
  // // tg.viewportStableHeight = 0
  tg.isClosingConfirmationEnabled = true
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  tg.headerColor = '#F5C71A'
  tg.backgroundColor = '#1C1C1E'

  // useEffect(() => {
  //   const overflow = 100
  //   document.documentElement.style.height = 'auto'
  //   document.body.style.overflowY = 'hidden'
  //   document.body.style.marginTop = `${overflow}px`
  //   document.body.style.height = window.innerHeight + overflow + "px"
  //   document.body.style.display = `flex`
  //   document.body.style.flexDirection = `column`
  //   window.scrollTo(0, overflow)
  //
  //   const root = document.getElementById('root')
  //   root.style.flexGrow = '1'
  //   root.style.overflow = 'auto'
  //
  //   let ts: number | undefined
  //   const onTouchStart = (e: any) => {
  //     ts = e?.originalEvent?.touches[0].clientY || e?.touches[0].clientY
  //   }
  //   const onTouchMove = (e: any) => {
  //     const scroll = root.scrollTop
  //     const te = e?.originalEvent?.changedTouches[e.changedTouches.length - 1].clientY || e.changedTouches[e.changedTouches.length - 1].clientY
  //     console.log('stats', ts, te, root.scrollTop)
  //
  //     if (scroll <= 0 && ts! < te) {
  //       console.log('prevent')
  //       e.preventDefault()
  //     } else {
  //     }
  //   }
  //   document.documentElement.addEventListener('touchstart', onTouchStart, {passive: false})
  //   document.documentElement.addEventListener('touchforcechange', onTouchStart, {passive: false})
  //   document.documentElement.addEventListener('touchmove', onTouchMove, {passive: false})
  //
  //   return () => {
  //     document.documentElement.removeEventListener('touchstart', onTouchStart)
  //     document.documentElement.removeEventListener('touchmove', onTouchMove)
  //   }
  // }, []);

  return (
    <WebAppProvider>
      <Provider store={store}>
        <SkeletonTheme baseColor="#2C2C2E" highlightColor="#3A3A3C">
          <div className="App">
            <YMInitializer accounts={[97132345]} options={{webvisor: true}} version="2" />
            <AppRouter/>
          </div>
        </SkeletonTheme>
      </Provider>
    </WebAppProvider>

  );
}

export default App;
