import {useEffect, useState} from "react";
import {Input} from "../../ui-kit/Input";
import {
    TrainingFilterWrapper,
    FilterVariant,
    VariantsWrapper,
    FiltersPageLayout,
    FiltersTitle, FilterInputWrapper, FilterVariantWrapper
} from './FiltersPage.styles'
import axios from "axios";
import {ReactComponent as CheckboxIcon} from './checkbox.svg'
import {ReactComponent as UncheckboxIcon} from "./Uncheckbox.svg";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {setTrainerFilter, setTrainingFilter} from "../Schedule/schedule.slice";
import {useNavigate} from "react-router-dom";
import {ReactComponent as BackIcon} from "../TrainingPageContent/back.svg";
import {BackBtn} from "../TrainingPageContent/TrainingPageContent.styles";
import {ReactComponent as SearchSvg} from './Search.svg'
import {useSendToAuth} from "../../hooks/useSendToAuth";
import {ERoutes} from "../../app/AppRouter.types";
import {FiltersLayout} from "../../app/layouts";
import {BackButton} from "@vkruglikov/react-telegram-web-app";
import {FadeAnimation} from "../../ui-kit/FadeAnimation";
import {AbsolutePreloader} from "../../ui-kit/Preloader";

export interface ITrainer {
    ID: string
    Name: string
    Position: {
        Title: string
        ID: string
    }
    Department: {
        Title: string
        ID: string
    }
    Photo: string
}

export const TrainerFilter = () => {
    const [variants, setVariants] = useState<ITrainer[]>([])
    const [searchValue, setSearchValue] = useState<string>('')
    const [selectedOptions, setSelectedOptions] = useState<string[]>([])
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const sendToAuth = useSendToAuth()

    const handleGetBack = () => {
        navigate(-1)
    }

    const handleGetVariants = () => {
        const res = axios.get('https://api.profitnes.club/api/get-trainers').then((res) => {
            setVariants(res?.data?.Parameters)
        }).catch((err) => {
            if(err?.response?.status === 401) {
                sendToAuth()
            }

            if(err?.response?.status[0] === 4 || err?.response?.status[0] === 5) {
                navigate(ERoutes.MAIN)
            }
        }).finally(() => {

        })
    }

    useEffect(() => {
        handleGetVariants()
    }, [])

    const handleSelectOption = (option: string) => {
        if(selectedOptions.includes(option)) {
            const filteredOptions = selectedOptions.filter(opt => opt !== option)
            setSelectedOptions(filteredOptions)
        } else setSelectedOptions([...selectedOptions, option])
    }

    const handleSubmitFilters = () => {
        dispatch(setTrainerFilter(selectedOptions));
        navigate("/");
    };

    return (
        <>
            <FadeAnimation show={Boolean(variants.length == 0)}>
                <AbsolutePreloader/>
            </FadeAnimation>
            <FadeAnimation show={Boolean(variants.length > 0)}>
                <FiltersLayout onClick={handleSubmitFilters}>
                <FiltersPageLayout>
                    <BackButton onClick={handleGetBack}/>
                    <TrainingFilterWrapper>
                        <FiltersTitle>Тренер</FiltersTitle>
                        <FilterInputWrapper>
                            <SearchSvg />
                            <Input
                                variant={'primary'}
                                placeholder={'Искать'}
                                onChange={e => setSearchValue(e.target.value.toLowerCase())}
                            />
                        </FilterInputWrapper>

                        <VariantsWrapper>
                            {variants
                                .filter(variant => variant.Name.toLowerCase().includes(searchValue))
                                .map((variant, index) => (
                                    <FilterVariantWrapper
                                        onClick={e => handleSelectOption(variant.Name)}
                                        key={index}>
                                        <FilterVariant>
                                            {variant.Name}
                                        </FilterVariant>
                                        {selectedOptions.includes(variant.Name) ? <CheckboxIcon /> : <UncheckboxIcon />}
                                    </FilterVariantWrapper>

                                ))}
                        </VariantsWrapper>
                    </TrainingFilterWrapper>
                </FiltersPageLayout>
            </FiltersLayout>
            </FadeAnimation>
        </>
    );
};