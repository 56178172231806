import styled from "styled-components";
import { rem } from "../../app/styles";

export const ScheduleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${rem(15)};
  align-items: center;
  gap: ${rem(25)};
`;

export const ScheduleCardWrapper = styled.div`
  display: flex;
  gap: ${rem(15)};
  flex-direction: column;
  width: 100%;
`;

export const ScheduleDate = styled.p`
  color: var(--color-text);
  font-size: ${rem(17)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
`;
