import styled from "styled-components";
import {rem} from "../../app/styles";
import {Button} from "../../ui-kit/Button";

interface ShoppingCartPageInputProps {
    exceeded: boolean; // Define the exceeded prop
}

export const VariantsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1 1 auto;
`;

export const FilterVariantWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--color-third);
`;

export const Title = styled.p`
  color: var(--color-text);
  font-size: ${rem(17)};
    margin-top: ${rem(10)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: flex-start;
`;

export const ProceedPaymentButton = styled(Button)`
    background-color: var(--color-action);
    color: var(--color-primary);
    width: calc(100% - 10%);  /* Full width minus the parent's padding */
    font-size: ${rem(16)};
    border-radius: 14px;
    position: fixed;       /* Position relative to the parent container */
    bottom: 10px;             /* 10px from the bottom of the parent container */
    left: 5%;                 /* Align with the parent's left padding */
    z-index: 1000;            /* Ensure it stays above other elements */
`;
export const FilterVariant = styled.div`
    color: var(--color-text);
    width: 100%;
    height: ${rem(70)};
    font-size: 13px;
    font-weight: 600;
    line-height: 18px;
    padding-left: ${rem(5)};
    padding-top: ${rem(20)};
    padding-bottom: ${rem(20)};
    margin: ${rem(2)};
    margin-right: ${rem(10)};
    display: flex;
    //align-self: end;
    align-items: center;
    justify-content: space-between;
`;

export const ShoppingCartPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  padding: 5%;
  position: relative;
`

export const ShoppingCartPageTitle = styled.p`
  color: var(--color-text);
  text-align: center;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(25)};
`

export const SupportCartPageInput = styled.div<ShoppingCartPageInputProps>`
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};
  margin-top: 40%;
  padding: ${rem(15)};
  align-self: flex-end;
  color: var(--color-text);
  font-size: ${rem(13)};
  font-weight: 400;
  line-height: ${rem(16)};
  border-radius: ${rem(20)};
  border: 2px solid var(--color-bg); /* Added border */
  width: 100%; /* Added width */
  position: relative;

  textarea {
    height: ${rem(250)}; /* Adjust the height accordingly */
    padding: ${rem(10)}; /* Added padding */
      margin-bottom: ${rem(20)};
    resize: none;
    font-size: ${rem(13)};
    font-weight: 400;
    line-height: ${rem(20)};
    outline: none;
      background-color: var(--color-primary);
      color: var(--color-text);
      word-break: break-word;
  }

    input::placeholder {
        color: var(--color-text); /* Set placeholder text color */
        vertical-align: top; /* Align placeholder to start at top position */
    }

  .symbol-counter {
    position: absolute;
    bottom: ${rem(10)};
    right: ${rem(10)};
    color: ${(props) => (props.exceeded ? "red" : "inherit")};
  }
`;
